@import '../../../../base/scss/variables';

#merchant-it {
  .header-wrapper {
    background-color: $gray;
    color: $white;

    .icon {
      background: url('../../../../base/img/merchant-form/header5_bg.svg');
    }
  }
}
