@import '../../../../base/scss/variables';

.tour_item {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.active {
    background-color: #e4faff;
  }

  .tour_id {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 23;
    color: $purple;
    width: 100px;
    min-width: 100px;
    white-space: nowrap;
    text-align: center;
    background-color: white;
    overflow: hidden;
    min-height: 70px;
    line-height: 70px;
  }

  .tour_progress_overview {
    display: flex;
    flex-grow: 1;
    padding: 10px;
    justify-content: space-between;

    .status {
      display: flex;
    }
  }
}
