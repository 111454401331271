@import '../../../scss/variables';

.statusBadge {
  display: flex;
  min-height: 20px;
  width: fit-content;
  line-height: 12px;
  padding: 5px 7px 5px 5px;
  min-width: 24px;
  border-radius: 16px;
  font-family: ClanPro-News, sans-serif;
  font-size: 12px;
  color: $purple;
  background-color: $gray-light;

  > div:first-of-type {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    vertical-align: center;
  }

  &.default:not(:disabled) {
    > div:first-of-type {
      background-color: $blue;
    }
  }

  &.primary:not(:disabled) {
    > div:first-of-type {
      background-color: $blue;
    }
  }

  &.primaryPurple:not(:disabled) {
    > div:first-of-type {
      background-color: $purple-2;
    }
  }

  &.success:not(:disabled) {
    > div:first-of-type {
      background-color: $green;
    }
  }

  &.danger:not(:disabled) {
    > div:first-of-type {
      background-color: $red;
    }
  }

  &.warning:not(:disabled) {
    > div:first-of-type {
      background-color: $orange;
    }
  }

  &.warningYellow:not(:disabled) {
    > div:first-of-type {
      background-color: $yellow;
    }
  }

  &.dark:not(:disabled) {
    > div:first-of-type {
      background-color: $sepia-3;
    }
  }

  &.hidden {
    display: none !important;
  }
}
