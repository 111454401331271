@import '../../../../base/scss/variables';

.main_content {
  padding-right: 24px;
}

.main_title {
  font-size: 24px;
  margin: 50px 0 !important;
}

.linehaul_table {
  tbody {
    .table_header_row {
      font-family: ClanPro-Bold, sans-serif;
      color: $dark-gray;
      font-size: 12px;
      background-color: transparent;
      box-shadow: none !important;
      height: fit-content;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }

    .active {
      background-color: #e4faff !important;
    }

    tr {
      font-family: ClanPro-News, sans-serif;
      color: $purple;
      font-size: 12px;

      td {
        padding: 12px;
      }
    }
  }
}

.linehaul_table tbody tr td:nth-last-child(2) {
  width: 240px;
}

.linehaul_table tbody tr td:nth-child(2),
.linehaul_table tbody tr td:nth-child(3),
.linehaul_table tbody tr td:nth-child(4) {
  width: 200px;
}
.linehaul_table tbody tr td:first-of-type {
  width: 320px;
}

.thlocation {
  width: 110px;
  text-align: right !important;
}

.navs_legends {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 85px;
}

.noTours td {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12) !important;
  cursor: default;
  text-align: center;
  font-size: 14px;

  &:hover {
    background-color: $white;
  }
}

.back_drop {
  z-index: 10000 !important;
  color: $green;
}

.back_drop div {
  position: absolute;
}

.text {
  top: 55%;
  color: $white;
  font-family: ClanPro-News;
}

.location_progress {
  position: absolute;
}
