@import 'variables';
@import 'fonts';
@import 'texts';
@import 'buttons';
@import 'shadow';
@import 'table';
@import '~bootstrap/scss/bootstrap';
@import '~animate.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';

body {
  background-color: $super-light;
  width: 100vw !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-primary {
  color: $white;
}

.app {
  display: flex;
  text-align: center;

  &-logo {
    //todo
  }
  &-header {
    //todo
  }
  &-link {
    color: #61dafb;
  }

  main {
    flex: 1;
    background-color: $super-light;
    height: 100vh;

    &.expanded {
      flex: 0 0 95%;
      max-width: 100%;
      margin-left: 5%;
    }
  }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.opacity-grayscale {
  opacity: 0.6;
  filter: grayscale(100%);
  pointer-events: none;
}

.modal-dialog {
  max-width: none;
  justify-content: center;
  .modal-content {
    width: auto !important;
  }
}

.DayPicker {
  color: $purple;
  font-family: ClanPro-News, serif;

  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &Input-Overlay {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);

    &Wrapper {
      left: 13px;
      top: -4px;
    }
  }

  &-NavBar {
    > span {
      color: $purple;
      margin: 0;
      outline: none;

      svg {
        width: 12px;
        margin-top: -5px;
      }

      &:hover {
        color: lighten($purple, 15%);
      }

      &:first-of-type {
        right: auto;
        left: 1.5em;
      }
    }
  }

  &-Caption {
    > div {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }

  &-Weekday {
    color: $purple;
  }

  &-Day {
    width: 40px;
    height: 40px;

    &:hover:not(.DayPicker-Day--outside),
    &--selected {
      background-color: #8d1aff !important;
      border-radius: 4px !important;
      color: #fff !important;
    }

    &--today {
      color: $purple;
      position: relative;
      font-weight: normal !important;

      &:after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #8d1aff;
        position: absolute;
        bottom: 7px;
        left: 50%;
      }
    }
  }
}

.opacity-hidden {
  opacity: 0 !important;
}

@mixin animation-delay-x {
  @for $i from 1 through 50 {
    .animation-delay-#{$i} {
      animation-delay: #{$i * 200}ms;
    }
  }
}

@include animation-delay-x;

.form-control {
  font-family: ClanPro-News;
  font-size: 14px;
  &.search {
    border: none;
  }
}

.custom-select.no-border {
  border: none !important;
}

.flex-text {
  overflow-y: hidden;
}

.text-pre {
  white-space: pre !important;
}

@media (min-width: 768px) {
  .d-md-block {
    display: flex !important;
  }
}

.custom-checkbox {
  > .custom-control-input {
    &:checked ~ .custom-control-label::before {
      border-color: $purple;
      background-color: $purple;
    }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #adb5bd;
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
  }
  > .custom-control-label {
    &::before {
      width: 1.2rem !important;
      height: 1.2rem !important;
      border-radius: 0 !important;
      border-width: 2px;
    }
    &::after {
      width: 1.2rem;
      height: 1.2rem;
      background: no-repeat 50% / 70% 70%;
    }
  }
}

#toggle-sidebar-collapse {
  width: 32px;
  height: 32px;
  position: absolute;
  background: $green;
  color: $white;
  border-radius: $border-radius;
  transition: 500ms;
  top: 40px;
  cursor: pointer;
  right: -12px;

  &:hover {
    background: darken($green, 10%);
  }

  > svg {
    width: 16px;
    height: 16px;
    position: relative;
    top: 1px;
  }
}

.has-error {
  label {
    color: $red !important;
  }
  input,
  select,
  textarea,
  .MuiInput-underline {
    border-color: $red !important;
  }

  .MuiInputBase-root {
    border-color: $red !important;
    overflow: hidden;
  }
  .input-group-text {
    overflow: hidden;
    border-color: $red;
    border-right: none;
  }
}

.invalid-tooltip {
  display: block;
  position: relative !important;
  top: 1px;
  margin-bottom: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $red;
}

select:invalid {
  color: $light-gray-4 !important;

  option {
    color: $purple;
  }
}

@media (max-width: 1700px) {
  #toggle-sidebar-collapse {
  }
}

input::placeholder {
  color: $light-gray-3 !important;
}

input[inputmode='numeric']::placeholder,
textarea::placeholder {
  color: $light-gray-4 !important;
}
