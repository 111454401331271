@import '../../../../base/scss/variables';

.user-menu {
  .sign-out,
  .sign-out:hover {
    color: $red;
  }

  .menu-text {
    text-transform: capitalize;
  }

  .notification {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .badge {
      padding: 5px 15px;
      border-radius: 10px;
    }
  }

  .user-profile-container {
    padding: 24px;
    flex-direction: column;

    > span {
      display: flex;
      width: 100%;
      align-items: center;
    }

    .user-icon {
      color: $white !important;
      height: 48px;
      width: 48px;
      margin-right: 18px;
      border-radius: 50%;
    }
    .user p {
      color: $white !important;
      margin: 0;
      text-align: left;
    }

    .user-initials {
      background-color: $violet;
      flex: 0 0 48px;
      height: 48px;
      color: #fff;
      border-radius: 50%;
      font-size: 20px;
      font-family: ClanPro-News, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &::after {
      content: '';
      border: 1px solid #fff;
      background-color: #fff;
      width: 100%;
      margin-top: 32px;
      margin-bottom: -23px;
    }
  }
}
