@import '../../../../base/scss/variables';

.call-logs-container {
  display: flex;
  flex-direction: column;
}

.call-log {
  margin-bottom: 24px;
  border-radius: $border-radius;
  background-color: $white;
  flex: 1;

  > :first-child {
    border-bottom: 1px solid #0000001f;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .call-log-header {
    color: $purple;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 14px;
    text-align: left;
    padding: 24px;
  }

  .call-log-item-wrapper {
    display: flex;
    padding: 24px;
    flex-direction: column;
  }

  .call-log-item {
    display: flex;
    height: 24px;
    color: $purple;
    font-family: ClanPro-News, sans-serif;
    font-size: 14px;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    > img {
      margin-right: 24px;
      cursor: pointer;
    }

    > span {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $light-gray-4;
      width: calc(100% - 270px);
    }
  }
}
