@import '../../../../base/scss/variables';

#attempt-card {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: $border-radius;

  .no-delivery-data {
    color: $purple;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 14px;
  }

  > :first-child {
    border-bottom: 1px solid #0000001f;
  }

  .label {
    color: $purple;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 12px;
    text-align: left;

    &--title {
      @extend .label;
      font-size: 14px;
    }
  }

  .data {
    font-family: ClanPro-News, sans-serif;
    font-size: 13px;
    color: #201047;
    margin-top: 9px;
    text-align: left;

    &.font-bold {
      font-weight: 700;
    }

    span {
      padding-left: 4px;
      font-weight: normal;
      text-transform: capitalize;
    }

    > svg {
      position: relative;
      top: -2px;
    }

    > .font-sm {
      font-size: 11px;
    }
  }

  .set-driver-remark-link {
    font-family: ClanPro-News, sans-serif;
    font-size: 13px;
    text-decoration: none;
    color: $blue;
    margin: 10px;
    cursor: pointer;
    margin: 0px;
  }

  .section {
    margin-top: 24px;
    text-align: left;
  }

  .locate-gps {
    padding: 16px 0px;

    > a {
      width: 100%;
      font-size: 14px;
    }
  }

  .signature-area {
    width: 100%;
    border: 1px solid #0000001f;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;

    img {
      max-width: 100%;
    }

    a {
      text-decoration: none;
      color: $blue;
      margin: 10px;
      cursor: pointer;
    }

    &--unknown {
      @extend .signature-area;
      a {
        pointer-events: none;
        text-decoration: none;
        color: $gray-5;
        margin: 10px;
        cursor: pointer;
      }
    }
  }

  .status {
    flex: 1;
    padding: 10px;
    color: $white;
    font-family: ClanPro-News, sans-serif;
    font-size: 14px;
  }

  .delivered-state {
    background-color: $green;
  }

  .not-delivered-state {
    background-color: $red;
  }

  .in-transit-state {
    background-color: $blue;
  }

  .planned-state {
    background-color: $light-gray2;
  }

  > .wrapper {
    background-color: $white;
    padding: 24px;
    flex: 1;

    > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date {
        color: $gray-5;
        font-family: ClanPro-Wild-Bold-V2, sans-serif;
        font-size: 14px;
      }
    }

    > .tour-region-position {
      display: flex;
      justify-content: space-around;

      div {
        text-align: left;
        width: 100%;
      }
    }

    > .driver-profile {
      display: flex;
      margin: 20px 0px;
      align-items: center;

      img {
        margin-right: 25px;
        border-radius: 50%;
        width: 60px;
        height: 60px;
      }

      .default-avatar {
        margin-right: 25px;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        color: $light;
      }

      div {
        display: flex;
        flex-direction: column;
        text-align: left;

        :first-child {
          color: $purple;
          font-size: 14px;
          font-family: ClanPro-Bold, sans-serif;
        }

        :nth-child(2) {
          color: $blue;
          font-size: 12px;
          font-family: ClanPro-News, sans-serif;
        }
      }
    }
  }
}
