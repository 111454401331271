@import '../../../../../base/scss/variables';

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.merchant-list-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
}

.pageTitle {
  font-family: ClanPro-News, serif;
  font-size: 24px;
  color: #201047;
  margin: 50px 0 !important;
}

.listContainer {
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  flex: 1 1;
}
.merchant-overview-list {
  text-align: left;
  margin-bottom: 40px;
  &:last-of-type {
    margin-right: 0;
  }
  &:first-of-type {
    margin-left: 0;
  }

  .list-item-container {
    margin: 30px 40px 0 0;
    &:last-of-type {
      margin-right: 85px;
    }
  }

  .text.letter {
    font-family: ClanPro-News, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: $purple;
  }

  .list-item {
    margin: 16px 0;
    width: 330px;
    height: 44px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
      background-color: darken($white, 5%);
    }

    .name {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $purple;
    }

    .id {
      font-family: 'ClanPro-News';
      font-size: 12px;
      color: $light-gray-4;
    }
  }
}

.add-client {
  min-width: 164px !important;
  height: 45px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .merchant-overview-list {
    .list-item {
      width: 200px;
    }
  }
}
