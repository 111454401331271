.drawer_content_loading {
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .no_outline:focus {
      outline: none;
    }
  }

  .middle {
    display: flex;
    padding-top: 53px;
    align-items: center;
    margin-bottom: 30px;
  }
}
