@import '../../scss/variables';

$sidebarWidth: 280px;

.sidebar {
  padding: 0 !important;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: $purple !important;
  width: $sidebarWidth;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 450ms ease-out;
  position: relative;
  z-index: 999;

  .brand {
    margin: 40px 32px 32px 24px;
    text-align: left;

    > div {
      display: block;
      width: 221px;
      height: 35px;
      background: url('../../img/sidebar-menu/trunkrs-logo.svg');
    }

    .logo {
      width: 180px;
      margin-left: -6px;
    }
  }

  .nav {
    overflow: hidden;
  }

  .nav-item {
    display: flex;
    align-items: center;

    > a {
      width: 100%;
      height: 56px;
    }

    .menu-text {
      white-space: nowrap;
      margin-top: 2px;
      text-transform: capitalize;
    }
  }

  &.collapsed {
    width: 96px;

    .menu-text {
      display: none;
    }

    .brand {
      > div {
        width: 52px;
      }
    }

    a.nav-link {
      svg,
      .tooltip-trigger {
        margin: auto;
        position: relative;
        left: -2px;
      }
      .menu-text {
        color: rgba(0, 0, 0, 0);
      }
      .tooltip-trigger {
        top: -3px;
      }
    }

    .sign-out {
      .tooltip-trigger {
        top: 0 !important;
      }
    }

    .user-menu {
      .user {
        display: none;
      }
    }

    .badge:not(.collapsed) {
      display: none;
    }

    .badge.collapsed {
      position: relative !important;
      top: 12px;
    }
  }

  .nav-link {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 20px 20px 20px 21px;
    font-family: ClanPro-News;
    font-size: 14px;
    color: $white;
    border-left: 4px solid transparent;
    cursor: pointer;
    text-align: left;

    svg {
      height: 22px;
      width: 22px;
      margin-right: 18px;
      vertical-align: middle;
    }

    &:hover {
      color: #fff;
      background-color: #2c1060;
    }

    &.active {
      color: $green;
      border-left-color: $green;
      background-color: darken($purple, 5%);

      > svg,
      .tooltip-trigger > svg {
        > path {
          fill: #00d763;
        }
      }
    }

    &.disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }

  &-heading {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}

.menu-tooltip {
  margin-left: 40px !important;
}

@media screen and (max-width: 1440px) {
  .nav-link {
    padding: 15px 15px 15px 16px !important;
  }
}
