.root {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.presetPanel {
  display: flex;
  flex-direction: column;
  padding: 32px 16px 16px 16px;
  border-right: 1px solid #cccc;
}

.presetButton {
  margin-top: 8px;
  background-color: white !important;
  box-shadow: 0 0 black !important;
  outline: none !important;
  font-size: 12px;
  font-family: 'ClanPro-News', serif;

  > span {
    color: white;
  }
}

.active {
  background-color: #8664ff !important;
  > span {
    color: white;
  }
}
.disabled {
  > span {
    columns: #f5f5f5;
  }
}
.calendarPanel {
  display: flex;
  flex-direction: column;
}
.pickerContainer {
  padding: 0 32px;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-left: 64px;
  border-top: 1px solid #e0e0e0;
}
