@import '../../../../base/scss/variables';

#set-final-state {
  width: 675px !important;

  .btn-no-delivery,
  .btn-deliver-to-neighbour {
    margin-left: 10px;
  }

  .wrapper {
    margin-bottom: 20px;
  }

  textarea {
    min-height: 134px;
  }

  .error-label {
    background-color: $red;
    color: $white !important;
    padding: 5px;
    width: 100%;
    top: -($border-radius);
    position: relative;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-bottom: 0;
  }
}
