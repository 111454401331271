@import '../../../../base/scss/variables';
@import '../../../../base/scss/shadow';

#search-bar {
  margin: 0;

  .clear-btn {
    position: absolute !important;
    top: 0;
    right: 25px;
    display: flex;
    height: 100%;
    align-items: center;
    color: $red !important;
    outline: none !important;

    svg {
      margin-right: 7px;
    }

    &:hover {
      color: darken($red, 10%);
    }
  }

  .search-button {
    padding: 0;
    > button {
      width: 100%;
    }
  }

  .search-icon {
    display: flex;
    position: absolute;
    left: 15px;
    font-size: 24px !important;
    height: 100%;
    align-items: center;
  }

  input.search {
    @extend .box-shadow;
    height: 60px;
    line-height: 60px;
    padding-left: 50px;

    &.searching {
      padding-right: 100px;
    }
  }

  .filter-container {
    display: flex;
    position: absolute;
    left: 40px;
    margin-top: 91px;

    button.filter {
      font-size: 14px;
      svg {
        margin-right: 5px;
        width: 12px !important;
      }
    }

    .filters {
      margin-left: 12px;
      display: flex;
    }

    .merchant-filter {
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      & > button {
        font-size: 14px;
        border-radius: 15px;

        &.active {
          color: $blue;
          border-radius: 15px 15px 0 0 !important;
        }

        svg {
          width: 8px;
          margin-left: 10px;
        }
      }

      & > ul {
        list-style: none;
        background: $white;
        border-radius: 0 0 15px 15px;
        max-height: 200px;
        overflow-y: scroll;
        margin-top: 0px;
        padding: 0px;

        li {
          text-align: left;
          padding: 6px 12px;
          cursor: pointer;

          &:hover {
            background: $light-gray;
          }
        }
      }
    }

    .delivery-date-filter-container {
      display: flex;
      flex-direction: column;

      & > button {
        font-size: 14px;
        border-radius: 15px;
        &.delivery-date.active {
          color: $blue;
        }
        &.datepicker {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        svg {
          width: 8px;
          margin-left: 10px;
        }
      }

      .DayPicker {
        @extend .box-shadow;
        background-color: $white;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        z-index: 1;
      }
    }

    .clear-filter-button-container {
      margin-left: 10px;
      & > button {
        font-size: 14px;
        border-radius: 15px;
        svg {
          width: 8px;
          margin: 0 10px 0 0;
          color: $red;
        }
      }
    }
  }

  .search-autocomplete-container {
    position: absolute;
    background-color: #fff;
    padding: 0 15px 15px 15px;
    margin-top: 57px;
    z-index: 1;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .shipments-table {
      margin: 0 !important;
    }
  }
}
