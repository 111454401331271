.status-label {
  font-size: 12px;
  font-family: ClanPro-News;
  padding: 5px 14px;
  border-radius: 10px;
  background-color: #efefef;
  color: #a2a2a2;
  margin: 5px 10px;
  text-align: center;

  &.cancelled,
  &.not-delivered {
    background-color: #ffeae9 !important;
    color: #ff5351 !important;
  }

  &.in-transit {
    background-color: #e4faff !important;
    color: #13d2fc !important;
  }

  &.delivered {
    background-color: #e6fff2 !important;
    color: #00d763 !important;
  }
}
