@import '../../../base/scss/variables';

.DayPickerInput {
  .form-control {
    width: 320px;
  }

  .form-control:read-only {
    background-color: $white;
  }

  .form-control:focus,
  .with-overlay {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important;
    border-color: #ced4da !important;
  }

  .backdrop {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block !important;
  }

  .DayPickerInput-Overlay {
    border-radius: $border-radius;
    border: 1px solid #ced4da;
    border-top: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    margin-left: -13px;
    max-width: 320px;

    .bottom-section-wrapper {
      border-top: 1px solid #ced4da;
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: space-between;
      padding: 10px 20px;
    }
  }
}
