@import '../../../scss/variables';

.button-container {
  display: inline-block;
}

.button {
  outline: none;
  border: none;
  background-color: $light;
  border-radius: 8px;
  -webkit-appearance: none;
  padding: 16px;
  display: flex;
  align-items: center;
  height: 45px;

  font-family: ClanPro-News, sans-serif;
  font-size: 12px;

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &.default {
    color: $dark-gray;

    &:hover:not(:disabled) {
      background-color: darken($light, 10%);
    }
  }

  &.info {
    color: $white;
    background-color: $blue;

    &:hover:not(:disabled) {
      background-color: darken($blue, 10%);
    }
  }

  &.warning {
    color: $white;
    background-color: $orange;

    &:hover:not(:disabled) {
      background-color: darken($orange, 10%);
    }
  }

  &.danger {
    color: $white;
    background-color: $red;

    &:hover:not(:disabled) {
      background-color: darken($red, 10%);
    }
  }

  &.success {
    color: $white;
    background-color: $green;

    &:hover:not(:disabled) {
      background-color: darken($green, 10%);
    }
  }
}
