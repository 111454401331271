$gray-700: #495057;

.modal {
  background-color: rgba($gray-700, 0.5);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &.dark {
    background-color: rgba($gray-700, 0.7);
  }

  .modal-content {
    border: none !important;
    background-color: transparent !important;
  }
}
