.map_container {
  height: 50vh;
  background-color: darkolivegreen;
  color: white;
  margin-bottom: 30px;
  transition: height 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &.add_height {
    height: 60vh !important;
    transition: height 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}
