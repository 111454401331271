.box-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.modal-shadow {
  -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2) !important;
}
