@import '../../base/scss/variables';
@import '../../base/scss/shadow';

#warehouse-container {
  > .title {
    font-size: 24px;
    margin: 60px 0 !important;
  }

  .barcode-icon {
    display: flex;
    position: absolute;
    left: 50px;
    font-size: 24px !important;
    height: 100%;
    align-items: center;
  }

  .actions {
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    right: 30px;
    align-items: center;

    button {
      margin-left: 10px;
      font-size: 12px;
      border-radius: 20px;
    }

    .btn-postpone {
      input {
        margin-left: 12px;
        border-radius: $border-radius;
        height: 25px;
        width: 150px;
      }
    }

    .btn-print {
      &:hover {
        background-color: $yellow;
        color: #70570b;
      }
    }
  }

  input.barcode {
    @extend .box-shadow;
    height: 76px;
    line-height: 76px;
    padding-left: 90px;
    padding-right: 570px;
  }

  .history {
    margin-top: 36px;

    .no-results {
      color: $gray !important;
      text-align: left;
      font-size: 12px;
    }

    &-action {
      height: 58px;
      align-items: center;
      @extend .box-shadow;
      background-color: $white;
      border-radius: $border-radius;
      margin: 12px 0;

      .col {
        padding: 5px !important;

        &:nth-child(1) {
          flex-basis: 75px;
          flex-grow: 0;
        }
        &:nth-child(2) {
          flex-basis: 125px;
          flex-grow: 0;
        }
        &:nth-child(4) {
          flex-basis: 150px;
          flex-grow: 0;
        }
      }

      p {
        margin: 0;
      }

      svg.icon {
        font-size: 24px !important;
        float: right;
      }

      button.icon {
        color: $gray;
        font-size: 12px;
      }
    }
  }

  .scan-notification-container {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;

    .notification {
      display: flex;
      align-items: center;
      height: 57px;
      min-width: 325px;
      width: auto;
      border-radius: $border-radius;

      p {
        margin: 0 37px;
      }

      .icon {
        font-size: 24px !important;
        margin-right: 18px;
        vertical-align: middle;
      }

      &.success {
        background-color: lighten($green, 5%);
        p {
          color: darken($green, 20%) !important;
        }
      }

      &.error {
        background-color: lighten($red, 15%);
        p {
          color: darken($red, 35%) !important;
        }
      }
    }
  }
}
