@import '../../../../base/scss/variables';

.shipmentActionsContainer {
  display: flex;
  margin-top: 16px;
  z-index: 1;
}

.active {
  > div > div {
    background-color: $green;
  }
}

.toastActionsContainer {
  display: flex;

  > span:first-of-type {
    min-width: 240px;
    white-space: nowrap;
    margin-right: 16px;
  }

  > span:last-of-type {
    display: flex;
    position: relative;
    top: -10px;

    button {
      height: 32px;
      outline: none;
      &:last-of-type {
        white-space: nowrap;
        margin-right: 48px;
      }
    }
  }
}
