.root {
  display: flex;
  background-color: #ffff;
  height: 40px;
  left: 0px;
  top: 0px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 0px 1px 0px #220c4a1f;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;

  select {
    flex: auto;
    border: 0;
    text-overflow: ellipsis;
  }

  select:focus-visible {
    outline: none;
  }
}
