@import '../../../../../base/scss/variables';

.Dialog-Container {
  height: unset !important;
}
.paperRoot {
  padding: 32px 32px 0 32px;
  max-width: 990px !important;
  min-height: 500px !important;
  margin: 64px 0 !important;
  max-height: calc(100vh - 128px) !important;
  // overflow-y: unset!important;
  overflow: hidden !important;
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
  }
}

.scrollable {
  max-height: calc(82vh - 128px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    margin: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-right: 8px solid $light-gray-4;
    box-shadow: none;
    border-radius: 2px;
    margin: 16px;
  }
}

#merchant-info-modal {
  hr {
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.32);
  }

  .input-group {
    .MuiInputBase-root {
      padding: 0 16px;
      height: 44px;
      border: 1px solid $light-gray-4;
      border-radius: 8px;
    }
    .MuiInputBase-root.Mui-focused {
      border-color: $light-gray-4 !important;
    }

    .MuiInputBase-multiline {
      height: auto !important;
      padding: 8px 16px;
    }

    .MuiFormControl-root {
      width: inherit;
    }
  }

  .textfield {
    height: 44px;
    input {
      border-left: 1px solid $light-gray-4 !important;
    }
  }

  .disabled-element {
    opacity: 0.4;
    pointer-events: none;
  }

  .section-enabled {
    opacity: 1 !important;
  }

  .header {
    display: flex;
    margin-bottom: 8px;
    .title {
      font-size: 20px;
      color: $purple;
      flex: 1 auto;
      font-weight: bold;
      line-height: 1.45;
      text-align: left;
      font-family: ClanPro-WideBold, sans-serif !important;
    }

    .close-icon {
      width: 30px;
      cursor: pointer;
    }
  }

  .merchant-id {
    font-family: ClanPro-WideNews, sans-serif;
    font-size: 12px;
    color: $light-gray-4;
  }

  .loading-header {
    display: flex;
    justify-content: flex-end;
    .close-icon {
      width: 30px;
      cursor: pointer;
    }
  }

  .collapseWrapper {
    overflow-x: hidden !important;
  }

  .address {
    min-width: 165px;
  }

  .input {
    padding-left: 8px;
    height: 45px;
  }

  .rootClass {
    width: 236px;
    padding: 0 8px 0 8px !important;
  }

  .create-user {
    height: 45px;
    > span {
      font-size: 12px;
      text-transform: capitalize;
      line-height: 1em;
    }
    > .MuiButton-startIcon {
      margin-bottom: 3px;
    }
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 14px !important;
    margin-left: 4px;
  }

  .notification {
    display: flex;
    color: $green;
    margin-top: 16px;
    margin-left: 8px;
    > div {
      margin-right: 8px;
    }
  }

  .danger {
    color: $red !important;
  }

  .export {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    > span {
      > button {
        padding: 0 24px;
      }
    }
  }

  .collection-container {
    margin-left: -8px !important;
  }

  .form {
    > button {
      margin-left: 8px !important;
      margin-top: 25px;
      width: 150px;
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .editFormClass {
    display: contents;
    .label {
      font-size: 10px;
      font-weight: bold;
      line-height: 1.5;
      text-align: left;
      color: $gray;
      margin-bottom: 8px;
    }
  }

  .btn-disable {
    background-color: #cccccc !important;
  }
}

@media screen and (max-width: 600px) {
  .displayContainer > div {
    padding: 0 !important;
  }
}
.displayContainer > div {
  &:first-of-type {
    padding: 0 !important;
  }
  padding-left: 24px;
}

.multiField {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 56px;
  font-family: ClanPro-WideBold, sans-serif !important;
  .input-group {
    height: 44px;
    border-radius: none;
    > input {
      height: auto;
    }
  }
  .form-control {
    &:focus {
      box-shadow: none !important;
      border: 1px solid $light-gray-4;
      color: $purple;

      + div > .input-group-text {
        border-color: $light-gray-4 !important;
      }
    }
  }
}

.field-block {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
  font-family: ClanPro-WideBold, sans-serif !important;

  .input-group-text {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-right: none;
    > span {
      color: $purple;
      font-weight: bold;
    }
  }

  .form-control {
    height: 100%;
    color: $purple;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

    &:focus {
      box-shadow: none !important;
      border: 1px solid $light-gray-4 !important;
      color: $purple;

      + div > .input-group-text {
        border-color: $light-gray-4 !important;
      }
    }
  }
}

.dropdownMenuContainer {
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    height: 45px;
    font-family: ClanPro-News, sans-serif;
    font-size: 16px;
    color: $purple;
    cursor: pointer !important;
    border-radius: 8px;
    border: solid 1px $light-gray-4 !important;
    outline: none !important;
    width: 100%;

    > div {
      text-align: left;
      z-index: 1;
    }

    > svg {
      position: absolute;
      right: 0;
      height: 16px;
      width: 16px !important;
      margin-right: 12px;
      color: $dark-gray;
    }
  }

  fieldset {
    border: none !important;
  }
}

.edit-popover {
  box-shadow: none !important;
  border: 1px solid $light-gray-4 !important;
  border-top: none !important;
  border-radius: 0 0 8px 8px !important;
  max-width: none !important;

  li {
    font-family: ClanPro-News, sans-serif;
    font-size: 16px;
    color: $purple;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  li[class*='Mui-selected'] {
    color: $blue;
  }

  li[class*='MuiMenuItem-root']:hover {
    // color: $blue;
  }

  li[hidden] {
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    height: 66%;
    border-left: 4px solid $light-gray2;
    box-shadow: none;
    border-radius: 2px;
  }
}

.error-container {
  display: flex;
  height: 32px;
  min-width: 150px;
  margin-top: 8px;

  span {
    color: $red;
    font-size: 10px;
    display: block;
    padding-left: 4px;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.formContainer {
  > form > div {
    &:first-of-type {
      padding: 0;
    }
    padding-left: 24px;
  }
}

@media screen and (max-width: 600px) {
  .formContainer form > div {
    padding: 0 !important;
  }
}

.form-spacing {
  > form > .MuiGrid-container {
    padding-right: 48px;
  }
}
