@import 'variables';
.button {
  border-radius: 5px;
  height: 39px;
  min-width: 39px;
  font-family: ClanPro-News, serif;

  &.lg {
    height: 60px;
  }

  &:disabled {
    opacity: 50%;
    cursor: no-drop;
  }

  .icon {
    background: transparent !important;
  }

  &.action:not(.icon) {
    background-color: $blue;
    color: #ffffff;
    &:hover {
      background-color: darken($blue, 5%);
    }
  }
  &.danger:not(.icon) {
    background-color: $red;
    color: $white !important;
    &:hover {
      background-color: darken($red, 5%);
    }
  }
  &.assist:not(.icon) {
    background-color: $yellow;
    color: $white;
    &:not(:disabled):hover {
      background-color: darken($yellow, 5%);
    }
  }
  &.success:not(.icon) {
    background-color: $green;
    color: $white; //#1b7042;
    &:not(:disabled):hover {
      background-color: darken($green, 5%);
    }
  }
  &.default:not(.icon) {
    background-color: $white;
    color: $purple;
    &:not(:disabled):hover {
      background-color: darken($white, 5%);
    }
  }
  &.inactive:not(.icon) {
    background-color: $light;
    color: $gray;
    &:not(:disabled):hover {
      background-color: darken($light, 10%);
    }
  }

  &.action.icon {
    // color: $blue;
    color: $purple;
    &:hover {
      color: darken($blue, 10%);
    }
  }
  &.danger.icon {
    color: $red;
    &:hover {
      color: darken($red, 10%);
    }
  }
  &.assist.icon {
    color: $yellow;
    &:hover {
      color: darken($yellow, 10%);
    }
  }
  &.success.icon {
    color: $green;
    &:hover {
      color: darken($green, 10%);
    }
  }
  &.default.icon {
    color: $purple;
    &:hover {
      color: darken($purple, 10%);
    }
  }

  &.inactive.icon {
    color: $gray;
    &:hover {
      color: darken($gray, 15%);
    }
  }
}
