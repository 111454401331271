@import '../../../scss/variables';

.autocomplete-container {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;

  .input {
    height: 45px;
    border-radius: 8px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 16px 8px 16px 16px;
    outline: none;
  }

  .suggestions-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.12);
    border-top: 0;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    width: inherit;
    margin-top: -10px;

    > div {
      height: 50px;
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $purple;
      text-align: left;
      padding: 18px 24px;
      cursor: pointer;

      &:hover {
        background-color: $light-gray;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .h-0 {
    height: 0;
    z-index: 1;
  }

  .hidden {
    display: none !important;
  }

  .no-input {
    pointer-events: none;
  }
}
