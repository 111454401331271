@import '../../../../base/scss/variables';

.driver {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.driver_info {
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  span:first-of-type {
    font: 12px/17px ClanPro-Bold;
    letter-spacing: 0px;
    color: $purple;
  }

  span:last-of-type {
    font: 12px/17px ClanPro-News;
    letter-spacing: 0px;
    color: $blue;
  }
}

.btn_loc {
  height: 62px;
  width: 90px;
  margin: 1px !important;
}

.btn_loc:hover {
  background-color: $light;
}

.btn_loc:focus {
  outline: none !important;
}

.btn_loc:disabled {
  cursor: not-allowed !important;
}

.location {
  color: $blue;
  font-size: 20px;
  margin: 12px;
}

.last_td {
  width: 110px;
  padding: 0px !important;
  text-align: right !important;
}

.on_time {
  color: $green !important;
}

.fifteen_delay {
  color: $orange !important;
}

.thirty_delay {
  color: $red !important;
}
