.pickerRoot {
  position: relative;
  overflow: hidden;
}
.calendars {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.controls {
  top: 24px;
}
.chevron {
  width: 32px;
  height: 32px;
}
.weekdays {
  top: 64px;
}

.fixedElement {
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
