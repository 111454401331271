@import '../../../../base/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0px 20px 0px !important;

  .main_title {
    font-size: 24px;
    margin: 0px !important;
  }
}

.tour_table {
  tbody {
    .table_header_row {
      font-family: ClanPro-Bold, sans-serif;
      color: $dark-gray;
      font-size: 12px;
      background-color: transparent;
      box-shadow: none !important;
      height: fit-content;

      &:hover {
        cursor: default;
        background-color: transparent;
      }
    }

    .active {
      background-color: #e4faff !important;
    }

    tr {
      font-family: ClanPro-News, sans-serif;
      color: $purple;
      font-size: 14px;

      &.noTours {
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12) !important;
        cursor: default;

        &:hover {
          background-color: #fff;
        }
      }

      td {
        padding: 12px;
      }

      :nth-child(1) {
        white-space: nowrap;
      }

      :nth-child(2) {
        white-space: nowrap;
      }
    }
  }
}
