@import '../../base/scss/variables';

#tour-overview {
  .header {
    .title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        color: $purple;
        font-size: 24px;
        font-family: ClanPro-News, serif;
      }

      div {
        font-family: ClanPro-News, sans-serif;
        font-size: 9px;
        text-align: right;

        .refresh-button {
          color: $blue;
          font-family: ClanPro-News, serif;
          font-size: 12px;
          text-transform: capitalize;
          border: none;
          background: none;
          display: block;
          padding: 0;

          .refresh-list-icon {
            margin-right: 6px;
          }
        }
      }
    }
    .title-description {
      font-family: ClanPro-News, serif;
      font-size: 12px;
      text-align: left;
      color: $dark-gray;
    }
  }
  .wrapper {
    &.loading {
      opacity: 0.3;
      //-webkit-filter: blur(1px);
      //filter: blur(1px);
    }
    #search-bar {
      margin: 0 0 45px 0;

      .container {
        padding: 0;

        input {
          &.with-results {
            border-radius: 5px 5px 0 0;
          }
        }
      }

      .driver-query {
        display: flex;
        width: 100%;
        position: absolute;
        z-index: 20;
        margin-top: 0px;
        flex-direction: column;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
        -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
        border-radius: 0 0 5px 5px;
        overflow: hidden;

        button {
          width: 100%;
          font-family: ClanPro-News, serif;
          font-size: 18px;
          background-color: $white;
          color: $dark-gray;
          box-shadow: 0 2px 2px $black-opaque;
          border: none;
          padding: 6px 12px;
          text-align: left;
          display: flex;
          flex: 1;
          justify-content: space-between;

          &:hover {
            background-color: $light-gray;
          }
        }
      }
    }
    .subCos {
      text-align: left;
      margin-bottom: 30px;

      button {
        font-size: 14px;
        font-family: ClanPro-News, serif;
        margin: 0 60px 16px 0;
        padding: 0;
        border: none;
        background: none;
        color: $dark-gray;

        &.active {
          font-family: ClanPro-Bold, serif;
          color: $purple;
        }

        &:focus {
          outline: none;
        }

        .delays {
          font-family: ClanPro-News, serif;
          font-size: 9px;
          color: $white;
          background-color: $red;
          border-radius: 30px;
          display: inline-block;
          padding: 2px 4px 0 4px;
          text-align: center;
          position: relative;
          top: -10px;
        }
      }
    }
    .drivers {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      button {
        font-family: ClanPro-News, serif;
        font-size: 10px;
        padding: 6px 12px;
        border-radius: 15px;
        box-shadow: 0 2px 2px $black-opaque;
        margin-right: 12px;
        margin-bottom: 12px;
        background-color: $white;
        border: none;
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }

        &.selected {
          -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
          opacity: 1;

          &:focus {
            outline: none;
          }
        }

        &.not-selected {
        }

        &.delayed {
          background-color: $red;
          color: $white;
        }

        img {
          width: 18px;
          height: 18px;
          border-radius: 100%;
          display: inline-block;
          overflow: hidden;
          margin-right: 6px;
        }

        .driver-icon {
          width: 18px;
          height: 18px;
          margin: 0 6px -4px 0;
        }
      }
    }
    .tours {
      padding-bottom: 24px;

      .wrapper {
        background: white;
        margin-top: 24px;
        border-radius: 5px;
        padding: 24px;

        .info-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 24px;

          .driver-position {
            font-family: ClanPro-Book, serif;
            font-size: 10px;
            color: $gray;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 12px;

            span {
              margin-bottom: 6px;
            }

            .box {
              border: 1px solid $light;
              border-radius: 5px;
              padding: 6px 12px;
              font-family: ClanPro-Bold, serif;
              font-size: 12px;
              color: $purple;

              &.advanced-view-btn {
                background-color: $blue;
                color: $white;
                font-family: ClanPro-News, sans-serif;
                font-size: 12px;
              }

              a,
              a:active,
              a:focus {
                font-size: 12px;
                font-family: ClanPro-Bold, serif;
                color: $purple;
                text-decoration: none;
              }

              a:hover {
                text-decoration: underline;
              }
            }
          }

          .current-position {
            margin-right: 12px;
            color: $gray;
          }

          .driver-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h1 {
              font-family: ClanPro-Bold, serif;
              font-size: 18px;
              color: $purple;

              &.delayed {
                color: $red;
              }
            }
            h2 {
              font-family: ClanPro-Book, serif;
              font-size: 12px;
              color: $gray;
            }

            .driver-phone-number {
              text-decoration: none;
              color: $green;

              & > svg {
                margin-right: 6px;
              }
            }
          }
        }
        .tour-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .time {
            font-family: ClanPro-Bold, serif;
            font-size: 12px;
            color: $purple;
            width: 50px;
          }

          .stop-bar {
            margin: 0 12px;
            flex: 1;
            background-color: $super-light;
            border-radius: 5px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: space-around;
          }
        }
        .position-row {
          margin: 12px 62px 0;
          display: flex;
          align-items: flex-start;
          justify-content: space-around;
          padding: 0;

          .position {
            margin: 0;
            font-family: ClanPro-Book, serif;
            font-size: 8px;
            color: $gray;
            width: 12px;
            text-align: center;

            &.current {
              width: 16px;
            }
          }
        }
        .legend {
          display: flex;
          padding: 12px;
          background: $super-light;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;
          font-family: ClanPro-Book, serif;
          font-size: 10px;
          color: $gray;
          margin: -24px -24px 24px -24px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          .stop-container {
            white-space: nowrap;
            display: flex;
            margin: 12px;
            align-items: center;

            .stop {
              margin-right: 6px;
            }
          }
        }
        .tour-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 100;

          .backdrop {
            background: $black-opaque-2 0 0 no-repeat padding-box;
            padding: 60px;
            height: 100vh;
            display: flex;
            flex-direction: column;

            .modal-container {
              -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
              -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
              box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
              background: $white 0 0 no-repeat padding-box;
              border: 1px solid $black-opaque-3;
              border-radius: 5px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              flex: 1;

              .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px;
                border-bottom: 1px solid $black-opaque-3;
                align-items: center;

                .title {
                  font-family: ClanPro-Bold, sans-serif;
                  font-size: 18px;
                  line-height: 100%;
                }

                button {
                  background: none;
                  border: none;
                  padding: 0;
                  align-items: center;
                  display: flex;

                  .close-button {
                    width: 20px;
                    height: 20px;
                    color: $dark-gray;
                  }
                }
              }

              .driver-info {
                background: $gray-light 0 0 no-repeat padding-box;
                border-bottom: 1px solid $black-opaque-3;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                font-family: ClanPro-News, sans-serif;
                font-size: 14px;
                color: $purple;

                svg {
                  margin-right: 6px;
                  width: 14px;
                  height: 14px;
                }

                .name {
                  padding-right: 48px;
                  display: flex;
                  align-items: center;

                  .driver-icon {
                    width: 36px;
                    height: 36px;
                    border-radius: 36px;
                    margin: 0 12px;
                    display: inline-block;
                  }
                }

                .phone-number {
                  color: $green;
                  padding-right: 48px;
                }

                .shipments {
                  padding-right: 48px;
                }

                .eta {
                  padding-right: 48px;
                }
              }

              .tour-overview {
                margin: 0;
                background-color: $white;
                display: flex;
                overflow: hidden;
                flex: auto;

                .shipments {
                  overflow-y: auto;
                  border-right: solid 1px $black-opaque-3;
                  height: 100%;

                  .shipment {
                    padding: 24px 12px;
                    border-bottom: solid 1px $black-opaque-3;

                    &.not-scanned {
                      background: $white 0 0 no-repeat padding-box;
                    }

                    &.delivered {
                      background: $green-light 0 0 no-repeat padding-box;
                    }

                    &.delivered-to-neighbour {
                      background: $green-light 0 0 no-repeat padding-box;
                    }

                    &.not-delivered {
                      background: $red-light 0 0 no-repeat padding-box;
                    }

                    &.declined-by-driver {
                      background: $white 0 0 no-repeat padding-box;
                    }

                    .container {
                      display: flex;
                      flex-direction: row;

                      .position {
                        width: 39px;
                        height: 39px;
                        display: flex;
                        color: $purple;
                        font-family: ClanPro-Bold, sans-serif;
                        font-size: 16px;
                        border-radius: 5px;
                        background: $black-opaque-3 0 0 no-repeat padding-box;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                      }

                      .details {
                        font-family: ClanPro-News, sans-serif;
                        font-size: 14px;
                        color: $purple;
                        text-align: left;
                        padding-left: 18px;
                        display: flex;
                        flex: auto;
                        flex-direction: column;

                        .address {
                          margin-bottom: 16px;

                          .postal-code {
                            color: $dark-gray;
                          }
                        }

                        .recipient {
                          margin-bottom: 16px;

                          .phone-number {
                            color: $green;
                          }
                        }

                        .footer {
                          font-family: ClanPro-Bold, serif;
                        }
                      }

                      .eta {
                        width: 47px;
                        font-family: ClanPro-News, sans-serif;
                        font-size: 14px;
                        color: $purple;
                        text-align: right;
                        display: flex;
                      }
                    }

                    .status {
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      font-family: ClanPro-Bold, sans-serif;
                      font-size: 14px;

                      .delayed {
                        color: $red;
                      }
                    }
                  }
                }

                .map {
                  .position {
                    font-size: 10px;
                    margin-top: -34px;
                    margin-left: -7px;
                    color: $black;
                    text-align: center;
                    display: block;
                    position: fixed;
                    background-color: $white;
                    width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
        }
        .stop {
          height: 12px;
          width: 12px;
          border-radius: 12px;
          background-color: $blue;
          position: relative;
          cursor: pointer;
          border: solid 1px $blue;

          &.delivered {
            border-color: $green;
            background-color: $green;
          }
          &.delivered-to-neighbour {
            border-color: $green;
            background: $white;
          }
          &.not-delivered {
            border-color: $red;
            background-color: $red;
          }
          &.delayed {
            border-color: $yellow;
            background-color: $yellow;
          }
          &.current {
            background-image: url(../shipment/components/quickTour/assets/star.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 10px 10px;
            width: 16px;
            height: 16px;
            border-radius: 16px;
          }
          &.not-scanned {
            border-color: $gray;
            background: $gray;
          }
          &.declined-by-driver {
            border-color: $gray;
            background: $gray;
          }

          &:hover {
            .overlay {
              display: block;
            }
          }

          .overlay {
            display: none;
            position: absolute;
            background: $white;
            border: 5px;
            padding: 6px;
            width: 120px;
            top: -60px;
            left: -60px;
            border-radius: 5px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12) !important;

            &::after {
              content: '';
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: $white transparent transparent transparent;
            }

            h1 {
              font-family: ClanPro-Bold, serif;
              font-size: 10px;
              color: $purple;
              margin: 0;
            }

            p {
              margin: 0;
              font-family: ClanPro-Book, serif;
              font-size: 8px;
              color: $gray;
            }
          }
        }
      }
      .box-shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
        -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12) !important;
      }

      .noTours {
        color: $purple;
        margin-top: 30px;
      }
    }
    .no-results {
      text-align: center;

      .header {
        margin: 18px;
        color: $gray;
        font-family: ClanPro-Bold, sans-serif;
        font-size: 24px;
      }

      .content {
        color: $gray;
        margin-bottom: 36px;
        font-size: 20px;
        font-family: 'ClanPro-WideNews', sans-serif;
      }
    }
  }
}
