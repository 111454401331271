@import '../../../../../scss/variables';
.root {
  display: flex;
  align-items: center;
  padding: 16px;
  .input {
    min-width: 232px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid $gray-2;
    margin-right: 8px;
    padding: 0 8px;
    outline: none !important;
  }
  input:focus {
    border-width: 2px !important;
    border-color: #8000ff !important;
  }

  > button {
    height: 40px;
    color: $purple !important;
  }
}
