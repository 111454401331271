@import '../../base/scss/variables';
@import '../../base/scss/shadow';

#merchant-creation {
  margin-bottom: 40px;

  > .title {
    margin: 50px 0 !important;
    > h1 {
      font-size: 24px;
    }
  }

  label {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: $gray;
  }

  .text-field {
    text-align: left;

    > .MuiTextField-root {
      margin-bottom: 16px;
    }
  }

  .select-field {
    select,
    .MuiOutlinedInput-root {
      height: 45px;
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      color: $purple;
      margin-bottom: 16px;
    }

    .MuiOutlinedInput-root {
      cursor: pointer !important;
      > div {
        text-align: left;
        z-index: 1;
      }
      &.Mui-focused {
        border-color: $blue;
      }

      > svg {
        position: absolute;
        right: 0;
        height: 16px;
        width: 16px !important;
        margin-right: 12px;
        color: #888;
      }
    }

    fieldset {
      border: none !important;
    }

    > div {
      padding: 0 !important;
    }
  }

  .select-field-container {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    vertical-align: top;
    text-align: left;

    select {
      height: 45px;
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      margin-bottom: 16px;

      &:not(:invalid) {
        color: $purple;
      }
    }
  }

  .wrapper {
    @extend .box-shadow;
    width: 730px;
    padding-bottom: 25px;
    background-color: #fff;
    border-radius: 8px;

    .content {
      padding: 32px;
    }
  }

  .header-wrapper {
    height: 99px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:first-of-type {
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: flex-start;
    }

    .header-text {
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      font-weight: bold;
    }

    .header-subtext {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      text-align: left !important;
    }

    .icon {
      width: 259px;
      height: 100%;
    }
  }

  .input-group {
    input {
      padding: 16px;
      height: 45px;
      text-align: center;
    }
  }

  textarea:focus,
  .input-group-text:focus,
  .form-control:focus {
    box-shadow: none;
    border-color: $blue !important;
  }

  .form-control,
  textarea {
    border-radius: 8px;
    border-color: $light-gray-4;

    &:hover {
      border-color: $gray;
    }
  }

  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .custom-input-group {
    button {
      border-radius: 15px;
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $dark-gray;
      background-color: darken($light-gray, 5%);
      width: 290px;
      text-transform: none;
      box-shadow: none;
      outline: none;
      height: 28px;
      padding: 0 2px;
    }
  }
  .submit-button-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;

    .btn {
      height: 45px !important;
      margin-bottom: 10px;
    }
  }

  textarea {
    min-height: 90px;
    border-radius: 8px;
    font-family: ClanPro-News, sans-serif;
    font-size: 14px;
    color: $purple;
    outline: none;
    padding: 16px;
  }

  .MuiFormControl-root {
    z-index: 1;
  }

  .MuiInput-underline {
    padding: 16px;
    height: 45px;
    border: 1px solid $light-gray-4;
    border-radius: 8px;
    &:after,
    &:before {
      border-bottom: 0;
    }
    &:hover {
      border-color: $gray;
    }
  }

  .MuiInput-underline.Mui-focused {
    border-color: $blue;
  }

  .invalid-tooltip:not(.warning-notification) {
    display: none !important;
  }

  .warning-notification {
    max-width: fit-content;
    width: max-content;
    margin-top: -16px;
    margin-bottom: 16px;
  }
}

.select-menu-popover-container {
  box-shadow: none !important;
  border: 1px solid $blue !important;
  border-top: none !important;
  border-radius: 0 0 8px 8px !important;
  max-width: none !important;

  .MuiMenuItem-root.Mui-selected {
    color: $blue;
  }

  .MuiMenuItem-root:hover {
    color: $blue;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    height: 66%;
    border-left: 4px solid #ccc;
    box-shadow: none;
    border-radius: 2px;
  }
}

.nav-wrapper {
  .nav-link {
    padding: 0 !important;
    font-size: 12px !important;
    > svg {
      margin-right: 12px !important;
    }
    > span {
      font-size: 12px !important;
    }
  }
}
