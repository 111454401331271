@import '../../../../../../base/scss/variables';

.call-recording-wrapper {
  display: flex;
  flex-direction: column;
}

.call-recording-header {
  font-family: ClanPro-News, sans-serif;
  font-size: 12px;
  background-image: url('../../images/call-recording-player-header.svg');
  background-size: cover;
  height: 61px;
  line-height: 61px;
  text-align: left;
  color: #fff;
  padding-left: 24px;
}

.call-recording-player {
  display: flex;
  height: 72px;
  padding: 26px;
  align-items: center;

  .play-button {
    margin-right: 24px;
    cursor: pointer;
    > svg {
      color: $purple;
      height: 20px;
      width: 24px;
    }
  }

  .call-recording-controls {
    display: inline-block;
  }

  .call-recording-progress {
    display: flex;
    align-items: center;

    .current-time,
    .duration {
      color: $dark-gray;
      font-size: 12px;
      font-family: ClanPro-News, sans-serif;
    }

    .duration {
      margin-right: 26px;
    }

    .seek-slider {
      display: flex;
      align-items: center;
      height: 6px;
      color: $light;
      width: 345px;
      background-color: #ededed;
      margin: 0 16px 2px 16px;
      border-radius: 8px;

      .knob {
        position: relative;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        background-color: $blue;
        transition: left 250ms;
      }
    }
  }

  .call-recording-volume-control {
    display: flex;
    flex-direction: column;
    position: relative;

    &.show-control {
      top: -62px;
    }

    > div > svg {
      cursor: pointer;
      color: $purple;
      height: 24px;
      width: 24px;
    }

    .volume-slider-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 116px;
      width: 24px;
      background-color: $dark-gray;
      padding: 8px 4px;
      border-radius: 8px;
      margin-bottom: 8px;

      .volume-slider {
        position: absolute;
        width: 10px;
        height: 100px;
        background-color: $light-gray-1;
        //background: linear-gradient(to top, $green 44%, $light-gray-1 0);
        border-radius: 5px;
      }

      .knob {
        position: relative;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.32);
      }
    }
  }
}

.call-recording-modal {
  > div {
    > div {
      > div {
        width: 600px !important;
        > div:nth-of-type(1) {
          height: 56px !important;
          > h3 {
            font-size: 12px !important;
          }
        }
        > div:nth-of-type(2) {
          padding: 0 !important;
        }
      }
    }
  }
}
