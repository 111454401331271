.card {
  display: flex;
  width: 468px;
  padding: 0;
  overflow: hidden;

  .sideContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff8f5e;
    width: 48px;

    .icon {
      color: #fff;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 24px;

    h5 {
      color: #ff8f5e;
      text-transform: uppercase;
      font-family: ClanPro-Bold, sans-serif;
      font-size: 14px;
    }

    .messageContainer {
      padding-left: 42px;
      padding-right: 24px;
      margin: 18px -24px 0 -24px;
      max-height: 180px;
      overflow-y: auto;

      li {
        font-family: ClanPro-News, sans-serif;
        font-size: 12px;
        text-align: left;
      }
    }

    .button {
      border-color: #ff8f5e !important;
      color: #ff8f5e;
      margin-top: 18px;
      padding: 12px 24px;
      outline-color: #ff8f5e;

      &:hover {
        background-color: #ff8f5e10;
      }

      span {
        font-size: 14px;
        text-transform: none !important;
        margin: 0;
      }
    }
  }
}
