@import '../../../../base/scss/variables';

#comments-panel {
  text-align: left;
  margin-top: 50px;

  > h2.text {
    font-size: 16px !important;
    margin-bottom: 12px;
    font-family: ClanPro-Bold, sans-serif;
  }

  .wrapper {
    .comment,
    .reply-comment {
      height: auto;
      border-radius: $border-radius;
      background-color: $white;
      padding: 12px;
      align-items: center;
      margin: 12px auto;

      textarea {
        min-height: 46px;
        border: none !important;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        line-height: 46px;
      }

      button {
        &.reply,
        &.remove-reply {
          margin: 0 !important;
          padding: 0 !important;
          min-width: 80px !important;
          font-size: 12px;
          svg {
            margin-left: 5px;
          }
        }
      }

      .user-icon {
        height: 40px;
        width: 40px;
        margin: 0 8px;
        border-radius: 50%;
        border: 1px solid yellow;
      }

      .user {
        .name {
          margin: 4px 0;
        }
        .timestamp {
          margin: 4px 0;
        }
      }

      .comment-text {
        margin: 15px 56px;
        white-space: pre-line;
      }

      .button.action {
        min-width: 98px;
        margin-top: 25px;
        font-size: 12px;
        margin-right: 10px !important;
      }
    }

    .reply-comment {
      border-radius: 0;
      border-top: 2px solid $light;

      .button.action {
        width: auto;
        margin-top: 25px;
        font-size: 12px;
      }

      textarea {
        margin-left: 58px;
        width: calc(100% - 58px);
        border: 1px solid $light !important;
      }
    }

    //.info {
    //  margin: 0 0 24px 0;
    //}
    //
    //.name {
    //  font-size: 14px;
    //  font-weight: bold;
    //  margin-bottom: 12px;
    //}
  }
}
