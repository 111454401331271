@import '../../../scss/variables';

.button {
  height: 40px;
  line-height: 24px;
  border: 0;
  outline: none;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 2px 4px rgba(34, 12, 74, 0.08);
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ClanPro-News, sans-serif;
  font-size: 14px;
  color: $purple;
  cursor: pointer;

  > svg {
    margin-right: 12px;
    > path {
      fill: $purple;
    }
  }

  &.icon {
    padding: 8px 8px;
    > svg {
      margin-right: 0;
    }
  }

  &.primary:not(:disabled) {
    background-color: $blue;
    &:hover {
      background-color: darken($blue, 5%);
    }
  }

  &.success:not(:disabled) {
    background-color: $green;
    &:hover {
      background-color: darken($green, 5%);
    }
  }

  &.danger:not(:disabled) {
    background-color: $red;
    &:hover {
      background-color: darken($red, 5%);
    }
  }

  &.warning:not(:disabled) {
    background-color: $orange;
    &:hover {
      background-color: darken($orange, 5%);
    }
  }

  &.default:not(:disabled) {
    background-color: $white;
    &:hover {
      background-color: darken($white, 2%);
    }
  }

  &.link,
  &.link-danger,
  &.link-success {
    outline: none !important;
    background-color: transparent;
    box-shadow: none;
  }

  &.link:not(:disabled) {
    color: $purple;
    &:hover {
      color: darken($purple, 10%);
    }
  }

  &.link-danger:not(:disabled) {
    color: $red-2;
    &:hover {
      color: darken($red-2, 10%);
    }
  }

  &.link-success:not(:disabled) {
    color: $green-2;
    &:hover {
      color: darken($green-2, 10%);
    }
  }

  &:disabled {
    background-color: $gray-light;
    color: $gray-2;
    pointer-events: none;

    > svg {
      > path {
        fill: $gray-2;
      }
    }
  }
}
