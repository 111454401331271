@import '../../scss/variables';

.on_time {
  color: $green !important;
}

.fifteen_delay {
  color: $orange !important;
}

.thirty_delay {
  color: $red !important;
}
