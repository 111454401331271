@import '../../../scss/variables';

.verticalTabContainer {
  width: 96px;
  height: fit-content;
  background-color: #fff;
  list-style-type: none;
  font-family: 'ClanPro-News', sans-serif;
  font-size: 14px;
  color: $purple;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
    0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
  border-radius: 4px;
  padding: 8px 16px 8px 8px;
  text-align: left;

  > li {
    width: 72px;
    height: 40px;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 16px;
    border-left: 2px solid $gray-2;
    cursor: pointer;

    &.selected {
      background-color: $light-gray-1;
      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 4px;
        background-color: $green;
        left: -2px;
      }
    }
  }
}

.horizontalMode {
  width: auto;
  box-shadow: none;
  margin-left: 5px;
  > li {
    width: fit-content;
    display: inline-flex;
    border-left: none;
    border-bottom: 2px solid $gray-2;
    padding: 6px 16px;

    &.selected {
      background-color: $light-gray-1;
      &::before {
        content: '';
        position: absolute;
        height: 4px;
        width: 100%;
        background-color: $green;
        left: 0;
        bottom: -2px;
      }
    }
  }
}
