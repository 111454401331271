@import '../../../scss/variables';

.table {
  padding: 0;
  width: 100%;
}

.tableHeaderRow {
  display: flex;
  padding: 0 16px;
  height: 40px;

  > th:not(:first-of-type) {
    padding: 0 5px;
  }
}

.tableHeader {
  display: flex;
  flex: 1 1;
  font-family: 'ClanPro-Bold', sans-serif;
  font-size: 10px;
  align-items: center;
  color: $purple;
}

.tableBody {
  display: block;
  //max-height: 70vh;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(34, 12, 74, 0.12), 0 1px 1px rgba(34, 12, 74, 0.12),
    0 2px 4px rgba(34, 12, 74, 0.08);
  border-radius: 8px !important;

  tr {
    background-color: #fff;
    padding: 0 16px;
    cursor: pointer;

    > td {
      color: $purple;
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      padding: 0 5px;

      > div {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
      }
    }
  }

  tr:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  tr:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    > td {
      border-bottom: 0;
    }
  }
}

.tableRow {
  display: flex;
  height: 55px;
}

.tableCell {
  display: flex;
  flex: 1 1;
  align-items: center;
  border-bottom: 1px solid $gray-4;
  text-align: left;
}
