@import '../../../../base/scss/variables';

.shipment-returns-tab {
  display: flex;
  flex-wrap: wrap;
  height: 45px;
  margin-top: 30px;
  align-items: center;
  justify-content: left;
  padding-right: 15px;
  padding-left: 15px;

  .tab {
    color: $gray !important;
    cursor: pointer;
    text-align: center;
    min-width: 90px;
    padding-right: 15px;

    &.active {
      font-family: ClanPro-Bold, sans-serif;
      color: $purple !important;
    }
  }
}

#no-shipments-found {
  > img.icon {
    width: 164px;
    height: 142px;
  }

  > label {
    margin-top: 20px;
  }

  height: calc(100vh - 233px) !important;
}
