@import '../../../../../base/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .header_title {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 24;
    color: $purple;
  }

  .no_outline:focus {
    outline: none;
  }
}
