@import '../../../../base/scss/variables';

#form-login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 450px !important;
  height: 255px;
  padding: 15px 35px;
  margin: 0 auto;
  border-radius: $border-radius;
  background-color: $white;

  .logo {
    img {
      height: 130px;
    }
  }

  .spinner-grow {
    margin-top: 25px;
  }

  #btn-login {
    text-align: left;
    width: 320px;
    margin: auto;
    height: 64px !important;

    img {
      height: 32px;
      margin: 5px 25px;
    }
  }
}

#form-login-container {
  background-size: cover;
  background: url('../../../../base/img/login-bg.png') center;
  position: fixed;
  left: 0;
  padding: 0;
}
