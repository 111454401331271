@import '../../../../base/scss/variables';

.wrapper {
  display: block;
  padding-bottom: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 1px rgba(34, 12, 74, 0.12), 0 1px 1px rgba(34, 12, 74, 0.12),
    0 2px 4px rgba(34, 12, 74, 0.08);
  text-align: left;
  max-height: 89vh;
  overflow-y: auto;

  &.empty {
    padding-bottom: 0;
  }

  &.separator {
    margin-bottom: 16px;
  }

  &::-webkit-scrollbar {
    width: 18px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    margin: 32px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-right: 8px solid $light-gray-4;
    box-shadow: none;
    border-radius: 2px;
    margin: 16px;
  }

  .hub-log-container {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      &:after {
        margin: 0 24px;
        padding-top: 22px;
        content: '';
        border-bottom: 1px solid $light-gray-5;
      }
    }

    .hub-log {
      display: flex;
      width: 100%;
      cursor: pointer;
      height: 40px;
      // line-height: 34px;
      padding: 0 0 0 24px;
      align-items: center;

      &:hover {
        background-color: $light-gray-1;

        > div:last-of-type {
          visibility: visible;
        }
      }

      @media (min-width: 1275px) and (max-width: 1411px) {
        height: 50px;
      }

      @media (min-width: 990px) and (max-width: 1274px) {
        height: 60px;
      }
    }

    .timestamp {
      font-family: ClanPro-Bold, sans-serif;
      color: $purple;
      font-size: 12px;
      flex: 0 0 93px;
    }

    .source {
      font-family: 'ClanPro-News', sans-serif;
      color: $purple;
      font-size: 12px;
    }

    .pill-container {
      background-color: $light-gray-1;
      padding: 5px 6px 6px 6px;
      height: 20px;
      display: flex;
      align-items: center;
      border-radius: 16px;

      .circle {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;

        &.hub-in {
          background-color: $blue;
        }

        &.hub-out {
          background-color: $yellow;
        }
      }

      .text {
        font-family: 'ClanPro-News', sans-serif;
        color: $purple;
        font-size: 12px;
        position: relative;
        top: 1px;
      }
    }
  }

  .date {
    font-family: ClanPro-Bold, sans-serif;
    color: $purple;
    font-size: 14px;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 14px;
  }

  .static-tooltip {
    height: 40px;
    position: absolute;
    width: max-content;
    color: $white;
    background: $purple;
    text-align: left;
    visibility: hidden;
    border-radius: $border-radius;
    padding: 4px 8px;
    right: 93%;
    margin-right: 8px;
    z-index: 999;

    > div {
      height: 14px;
      font-family: 'ClanPro-News', sans-serif;
      font-size: 10px;
      line-height: 14px;
      position: relative;
      top: 2px;
      padding: 0;

      &:last-of-type {
        font-weight: bold;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -8px;
      width: 0;
      height: 0;
      border-left: 8px solid #201047;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
  }

  .static-tooltip-distance {
    right: 90px;
    top: 1px;
    height: 30px;
    visibility: hidden;
    display: flex;
    align-items: center;
    padding-bottom: -2px;
    padding-top: 1px;

    &:after {
      margin-top: -6px;
      border-left: 8px solid #201047;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
  }
}
