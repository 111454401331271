@import '../../../../base/scss/variables';

#merchant-collection {
  .header-wrapper {
    background-color: $orange;
    color: $white;

    .icon {
      background: url('../../../../base/img/merchant-form/header3_bg.svg');
    }
  }

  #collection-zipcode {
    text-transform: uppercase;
  }
}
