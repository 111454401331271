@import '../../../../../scss/variables';

.root {
  .body {
    padding: 16px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, auto);

    .checkbox {
      display: flex;
      padding: 8px 16px;
      align-items: center;

      .label {
        font-size: 12px;
        font-family: ClanPro-News, serif;
        margin-left: 8px;
        color: $purple;
        line-height: 1.5;
      }
    }
  }

  .footer {
    border-top: 1px solid $gray-2;
    display: flex;
    justify-content: flex-end;
    padding: 4px 16px;

    > button {
      color: $purple !important;
      height: 40px;
    }
  }
}
