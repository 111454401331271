@import '../../../../base/scss/variables';

.empty-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 56px 64px;
  text-align: center;

  > div:nth-child(2) {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 16px;
    color: $purple;
    margin-bottom: 10px;
  }

  > div:nth-child(3) {
    font-family: ClanPro-News, sans-serif;
    font-size: 14px;
    color: $purple;
  }
}
