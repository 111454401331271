@import '../../../../../scss/variables';

.container {
  padding: 4px 8px;

  .label {
    font-size: 12px;
    font-family: ClanPro-Bold, serif;
    font-weight: 400;
    line-height: 1.43;
    color: $purple;
  }
  .root {
    background-color: $light-gray-5;
  }

  .closeIcon {
    height: 24px;
    width: 24px;
    margin-left: 8px;
  }
}
