@import '../../../base/scss/variables';

#navigation-back-container {
  > a {
    padding: 0px;
    display: flex;
    align-items: center;
    height: 100%;

    > .arrow {
      color: $purple;
      margin-right: 10px;
    }

    svg {
      @extend .arrow;
      height: 14px;
      width: 14px;
    }

    > .link-label {
      color: $purple;
      font-family: ClanPro-News;
      font-size: 14px;
      padding-top: 3px;
    }
  }
}
