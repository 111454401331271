@import '../../../scss/variables';

.notificationContainer {
  position: fixed;
  display: flex;
  height: 84px;
  min-width: 410px;
  background-color: rgba(255, 255, 255, 0.95);
  align-items: center;
  z-index: 999;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
    0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
  border-radius: 8px;

  &:after {
    content: '';
    height: 4px;
    border-top: 0;
    border-bottom-left-radius: 4px;
    background-color: $green;
    position: absolute;
    bottom: 0;
    animation-name: shrink-to-left;
    animation-duration: 5s;
    animation-timing-function: linear;
  }

  .icon {
    display: flex;
    flex: 0 0 56px;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;
    top: 18px;

    svg {
      color: $green;

      > path {
        fill: $green;
      }
    }
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    flex: 0 0 80%;
    text-align: left;
    padding-right: 15px;

    .header-text {
      font-family: ClanPro-Bold, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: $green-2;
      margin-bottom: 4px;
    }

    .sub-text {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $purple;
      position: relative;
      top: 4px;

      b {
        color: $blue;
      }
    }
  }

  .closeButton {
    position: absolute;
    color: $gray-2;
    font-size: 24px;
    font-weight: lighter;
    cursor: pointer;
    top: -6px;
    right: 10px;

    &:hover {
      color: gray;
    }
  }

  &.error {
    border-bottom-color: $red;
    .icon {
      > svg {
        color: $red;
        > path {
          fill: $red;
        }
      }
    }
    .message-container {
      .header-text {
        color: $red;
      }
    }
  }

  &.top-left {
    top: 15px;
    left: 24px;
  }

  &.top-right {
    top: 15px;
    right: 15px;
  }

  &.bottom-left {
    bottom: 20px;
    left: 24px;
  }

  &.bottom-right {
    bottom: 20px;
    right: 15px;
  }
}

@keyframes shrink-to-left {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
