@import '../../../scss/variables';

.select-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.select-input {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 16px 8px 16px 16px;
  width: 100%;

  & > div:first-of-type {
    width: inherit;
    pointer-events: none;
    text-align: left;

    > button {
      display: block;
      width: 100%;
      padding: 0 5px 0 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
      -webkit-appearance: none;
      outline: none;
    }
  }

  .placeholder {
    text-align: left;
    font-size: 11px;
    font-family: ClanPro-News, sans-serif;
    color: $dark-gray;
    line-height: 25px;
  }

  & > div:last-of-type {
    > svg {
      height: 20px;
      width: 30px;
      color: $dark-gray;
    }
  }
}

.select-options {
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin-top: -5px;
  padding-left: 0;
  width: inherit;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  > *:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  > button {
    display: block;
    width: 100%;
    border: none;
    background-color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    outline: none;
  }
}

.h-0 {
  height: 0;
  z-index: 1;
}
