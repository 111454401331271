@import '../../scss/variables';

.item_navigation {
  flex: 1;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;

  span {
    margin-right: 40px;
    margin-bottom: 14px;
    font-size: 14px;
    color: $dark-gray;
    font-family: ClanPro-News, sans-serif;
    cursor: pointer;

    &.active {
      font-family: ClanPro-Bold, sans-serif;
      color: $purple !important;
    }
  }
}

.navButton:focus {
  outline: none !important;
}
