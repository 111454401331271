@import '../../../../base/scss/variables';

#edit-recipient-form {
  min-width: 532px;

  .error-label {
    background-color: $red;
    color: $white;
    padding: 5px;
    width: 100%;
    top: -($border-radius);
    position: relative;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-bottom: 0;
  }

  .error-prompt {
    @extend .error-label;
    border-radius: $border-radius;
  }

  #input-city-container,
  #input-houseno-container {
    margin-left: -20px;
  }
}
