@import '../../../../base/scss/variables';

#sender-panel {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .wrapper {
    min-height: 180px;
    border-radius: $border-radius;
    background-color: $white;
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: ClanPro-News, sans-serif;
    font-size: 13px;

    .sender-name {
      color: $purple;
      font-weight: 700;
    }

    .sender-address {
      color: $purple;
      white-space: pre-wrap;
    }

    .sender-contact {
      color: $purple;
    }

    .sender-email {
      color: $blue;
      text-decoration: underline;
    }
  }
}
