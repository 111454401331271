@import '../../../../base/scss/variables';

.searchBar {
  > div:first-of-type {
    width: 100%;
  }
}

.filtersContainer {
  height: 48px;
  display: flex;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-5;

  > div {
    margin-right: 16px;
  }
}
