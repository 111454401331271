@import '../../../../base/scss/variables';

.drawer_content {
  padding: 24px;
  background-color: $super-light;
  width: 100%;
}

.text_on_box {
  color: white;
  padding: 5px;
  font-family: ClanPro-News, sans-serif;
  border-radius: $border-radius;
  font-size: 14px;

  &.remarks {
    max-width: max-content;
    padding-left: 20px;
    padding-right: 20px;

    div {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      span {
        margin-top: 3px;
      }
      :nth-child(2) {
        font-family: ClanPro-Bold, sans-serif;
        margin-right: 5px;
      }
    }
  }

  &.dark-gray {
    background-color: $dark-gray;
  }

  &.green {
    background-color: $green;
  }

  &.red {
    background-color: $red;
  }

  &.orange {
    background-color: $orange;
  }

  &.yellow {
    background-color: $yellow;
  }

  &.lighter-blue {
    background-color: $lighter-blue;
  }

  &.light-orange {
    background-color: $light-orange;
  }
}
