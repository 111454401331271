@import '../../../scss/variables';

$white: #fff;
$dark-grey: #a2a2a2;
$opacity-29: #00000029;

.modalBox {
  box-shadow: 0 2px 5px 0 rgb(202, 202, 202);
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  z-index: 999;

  &.hidden {
    display: none;
  }
}

.modalContent {
  background-color: $white;
  padding: 16px 32px;
  box-shadow: 0 2px 4px $opacity-29;
  border-radius: 8px;
  width: auto !important;
  position: absolute;

  > span:first-of-type {
    position: absolute;
    right: 16px;
    margin-top: 16px;
    font-size: 18px;
    color: $dark-grey;
    cursor: pointer;

    &:hover {
      color: darken($dark-grey, 10%);
    }
  }
}

.modalBody {
  font-size: 14px;
  font-family: ClanPro-News, sans-serif;
  text-align: left;
  color: $purple;
  min-width: max-content;
  margin-bottom: 16px;
}

.modalHeader {
  font-family: ClanPro-Bold, sans-serif;
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  color: $purple;
}

.modalFooter {
  display: flex;
  padding-top: 16px;
  justify-content: flex-end;

  > button:not(:last-of-type) {
    margin-right: 8px;
  }
}
