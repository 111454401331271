@import '../../scss/variables';
.custom-checkbox {
  > .custom-control-input {
    &:checked ~ .custom-control-label::before {
      border-color: #0074cc;
      background-color: #0074cc;
    }
    &:focus:not(:checked) ~ .custom-control-label::before {
      border-color: #adb5bd;
    }
    &:focus ~ .custom-control-label::before {
      box-shadow: none !important;
    }
  }
  > .custom-control-label {
    &::before {
      width: 24px !important;
      height: 24px !important;
      border-radius: 4px !important;
      border-width: 2px;
    }
    &::after {
      width: 24px;
      height: 24px;
      background: no-repeat 50% / 70% 70%;
    }
  }
}
