@import '../../../scss/variables';

.chip {
  display: inline-flex;
  align-items: center;
  padding: 8px 24px 8px 16px;
  width: max-content;
  color: $purple;
  background-color: $light;
  border-radius: 24px;
  margin-top: 2px;

  .close-icon {
    cursor: pointer;

    > svg {
      font-size: 16px;
      color: $dark-gray;
      position: relative;
      left: 10px;

      &:hover {
        color: darken($dark-gray, 10%);
      }
    }
  }
}
