.drawer_content_loading {
  padding: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .no_outline:focus {
      outline: none;
    }
  }

  .middle {
    display: flex;
    padding-top: 20px;
    align-items: center;
    margin-bottom: 30px;

    .middle_1 {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      div {
        margin-bottom: 5px;
      }
    }

    .middle_2 {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      div {
        margin-bottom: 10px;
      }
    }
  }
}
