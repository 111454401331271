@import '../../../../base/scss/variables';

.tour-progress {
  width: 900px !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 70vh;
  padding: 4px;

  .status {
    color: $blue !important;
  }
}
