@import '../../base/scss/variables';
@import '../../base/scss/shadow';

#shipment-returns-container {
  > .title {
    font-size: 24px;
    margin: 60px 0 !important;
  }

  .barcode-icon {
    display: flex;
    position: absolute;
    left: 50px;
    font-size: 24px !important;
    height: 100%;
    align-items: center;
  }

  input.barcode {
    @extend .box-shadow;
    height: 76px;
    line-height: 76px;
    padding-left: 90px;
    padding-right: 570px;
  }

  .scan-notification-container {
    margin-top: 12px;
    display: flex;
    align-items: flex-start;

    .notification {
      display: flex;
      align-items: center;
      height: 57px;
      min-width: 325px;
      width: auto;
      border-radius: $border-radius;

      p {
        margin: 0 22px;
        color: $white !important;
        &::first-letter {
          text-transform: uppercase;
        }
      }

      .icon {
        font-size: 28px !important;
        margin-right: 18px;
        vertical-align: middle;
      }

      &.success {
        background-color: $green;
      }

      &.error {
        background-color: $red;
      }
    }
  }

  .shipment-returns {
    .heading {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin: 10px 10px 0 10px;
      }

      button {
        .icon {
          margin-right: 5px;
        }
        height: 46px;
      }
    }
  }
}
