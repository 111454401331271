@import '../../../../base/scss/variables';

.container {
  background-color: white;
  padding: 0px;
  width: 529px;
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
  overflow: hidden;
  transition: height 0.2s ease-in-out;

  .tabs_control {
    display: flex;
    align-self: center;
    justify-content: space-around;

    div {
      width: 100%;
      font: 14px/21px ClanPro-Bold;
      color: $gray;
      padding: 30px;
      cursor: pointer;
      background: $light 0% 0% no-repeat padding-box;

      svg {
        transition: all 0.2s ease-in-out;
        transform: scale(0);
      }
    }

    .active {
      letter-spacing: 0;
      color: $purple;
      background: $white 0% 0% no-repeat padding-box;

      svg {
        color: $green;
        font-size: 24px;
        vertical-align: middle;
        margin-right: 12px;
        display: inline-block;
        transition: all 0.2s ease-in-out;
        transform: scale(1);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    transition: height 0.2s ease-in-out;

    div {
      padding: 24px;
    }

    .remark {
      max-width: 100%;
      text-align: start;
      padding: 24px 18px;
      color: $blue;

      &.optimize_remark {
        margin-top: 12px;
        color: $orange;

        span {
          span {
            font: 14px/21px ClanPro-Bold;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    font: 14px/21px ClanPro-News;
    background-color: white;
    position: fixed;
    width: inherit;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    .buttonPT {
      color: white;
      margin-left: 24px;
      background: linear-gradient(to right, #10accf 50%, #13d2fc 50%);
      background-color: #13d2fc;
      background-size: 205% 100%;
      background-position: right bottom;
      transition: background-position 0s ease-out;
    }

    .btn_started {
      background-position: left bottom;
      transition: background-position 3s ease-out;
    }
  }
}

.optimizeDisabled {
  cursor: not-allowed !important;
}
