@import '../../../../../base/scss/variables';

.driver_info {
  display: flex;
  margin-top: 30px;
  align-items: center;
  margin-bottom: 20px;

  .user_image_default {
    width: 58px;
    height: 58px;
    margin-right: 20px;
    color: $dark-gray;
  }

  .fallback {
    width: 100%;
    height: 100%;
  }

  .driver_part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    :nth-child(1) {
      font-family: ClanPro-Bold, sans-serif;
      font-size: 14px;
      color: $purple;
    }

    :nth-child(2) {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $dark-gray;
    }

    :nth-child(3) {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $blue;
    }
  }

  .driver_location {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;

    .btn {
      border-radius: 24px;
      font-size: 12px;
      font-family: ClanPro-News, sans-serif;
      display: flex;
      align-items: center;
      padding: 10px;

      svg {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
    }

    span {
      font-size: 11px;
      color: $dark-gray;
      font-family: ClanPro-News, sans-serif;
      padding-top: 5px;
    }
  }
}
