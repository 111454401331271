.custom-table-style {
  tr > th {
    font-size: 10px !important;
    font-family: ClanPro-Bold, sans-serif;
  }

  > tr {
    // th:nth-child(2),
    th:nth-child(3) {
      flex: 1 40px;
    }
    th:nth-child(2) {
      flex: 1 20px;
    }
    th:nth-child(8) {
      flex: 1 80px;
    }
    th:nth-child(6) {
      flex: 1 50px;
    }
    > th:nth-child(4) {
      flex: 1 110px;
    }
    > th:nth-child(5) {
      flex: 1 50px;
    }
    > th:nth-child(7) {
      flex: 1 120px;
    }
    > th:nth-child(9) {
      flex: 1 160px;
    }
    > th:nth-child(10) {
      flex: 1 250px;
    }
  }
  > tbody {
    > tr {
      // td:nth-child(2),
      td:nth-child(3) {
        flex: 1 40px;
      }
      td:nth-child(2) {
        flex: 1 20px;
      }
      td:nth-child(8) {
        flex: 1 80px;
      }
      td:nth-child(6) {
        flex: 1 50px;
      }
      td:nth-child(4) {
        flex: 1 100px;
      }
      td:nth-child(5) {
        flex: 1 50px;
      }
      td:nth-child(7) {
        flex: 1 120px;
      }
      > td:nth-child(9) {
        flex: 1 160px;
      }
      > td:nth-child(10) {
        flex: 1 250px;
      }
    }
  }
}
