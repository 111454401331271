@import '../../scss/variables';

.legends_box {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 8px;
  padding: 16px;
}

.legends {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span:last-of-type {
    margin-right: 16px;
  }
}

.dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin: 8px;
  vertical-align: middle;
}

.on_time {
  background-color: $green;
}

.fifteen_delay {
  background-color: $orange;
}

.thirty_delay {
  background-color: $red;
}
