@import '../../../../base/scss/variables';

.shipments-returns-table {
  // min-height: calc(100vh - 501px) !important;
  padding-right: 15px;
  padding-left: 15px;

  .table-heading {
    margin: 3px 5px 24px 5px;
  }

  .status {
    width: 280px;

    .return-status-label {
      padding: 10px 20px;
      border-radius: 19px;
      background-color: $red-light;
      color: $red;
      text-align: center;

      &.returned {
        background-color: $green-light;
        color: $green;
      }
    }
  }
}

#no-shipments-found {
  > img.icon {
    width: 164px;
    height: 142px;
  }

  > label {
    margin-top: 20px;
  }

  height: calc(100vh - 233px) !important;
}
