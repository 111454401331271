@import '../../../../base/scss/variables';

.customTable {
  margin-top: 20px;

  > tr {
    &.scrolling {
      box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
        0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
        0px 8px 12px rgba(34, 12, 74, 0.06),
        0px 16px 24px rgba(34, 12, 74, 0.04);
      clip-path: inset(0px 0px -24px 0px);
    }

    > th:nth-child(1) {
      flex: 0 1 50px;
    }
    > th:nth-child(2) {
      flex: 0 1 130px;
    }
    > th:nth-child(5) {
      flex: 0 1 100px;
      position: relative;
      left: -5px;
    }
    > th:nth-child(8) {
      flex: 0 1 120px;
      position: relative;
      left: -5px;
    }
    > th:nth-child(9) {
      flex: 0 1 240px;
    }
  }

  > tbody {
    max-height: 55vh;

    &.expanded {
      max-height: calc(55vh + 60px);
      scroll-behavior: smooth;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    tr {
      > td:first-of-type {
        padding-left: 0;
      }

      &.active {
        background-color: $light-blue;
        > td:first-of-type:before {
          content: '';
          width: 4px;
          height: 56px;
          margin-left: -17px;
          margin-right: 13px;
          background-color: $green;
        }
      }
      > td:nth-child(1) {
        flex: 0 1 50px;
      }
      > td:nth-child(2) {
        flex: 0 1 130px;
      }
      > td:nth-child(5) {
        flex: 0 1 100px;
      }
      > td:nth-child(8) {
        flex: 0 1 120px;
      }
      > td:nth-child(9) {
        flex: 0 1 240px;
      }
    }
  }

  .tableFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: $purple;
    height: 56px;
  }
}

.addressInfo {
  div:last-of-type {
    font-size: 10px;
    color: #666;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ClanPro-News, sans-serif;
  font-size: 12px;
  color: $purple;
  height: 56px;

  > svg {
    margin-right: 10px;
  }
}

.scrollShadow {
  position: absolute;
  margin-top: -24px;
  background: transparent;
  height: 24px;
  width: calc(100% - 78px);
  box-shadow: 0px 1px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 6px 6px rgba(34, 12, 74, 0.08),
    0px 12px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
}

.numberOfSelectionContainer {
  display: flex;
  font-family: ClanPro-News, sans-serif;
  font-size: 14px;
  color: $purple;
  position: absolute;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  height: 50px;
  z-index: 0;
}
