@import '../../../../base/scss/variables';

#merchant-invoicing {
  .header-wrapper {
    background-color: $blue;
    color: $white;

    .icon {
      background: url('../../../../base/img/merchant-form/header4_bg.svg');
    }
  }

  .input-group-text.prepend {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .shipment-count-label {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: $purple;
    width: 90px;
  }
}
