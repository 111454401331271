@import '../../../../base/scss/variables';

#daily-card,
.daily-card {
  margin-bottom: 24px;
  border-radius: $border-radius;

  > :first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .label {
    color: #888888;
    font-family: ClanPro-News, sans-serif;
    font-size: 11px;
    text-align: left;
  }

  .data {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: #201047;
    text-align: left;
  }

  .wrapper {
    background-color: $white;
    padding: 24px;
    flex: 1;

    &.out-of-freezer {
      padding: 36px 24px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.out-of-freezer {
        display: block;
      }

      .date {
        color: $purple;
        font-family: ClanPro-Bold, sans-serif;
        font-size: 14px;
        text-align: left;
      }

      .max-time,
      .accumulated-time {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date {
          font-size: 12px;
        }

        .time {
          color: $blue;
          font-family: ClanPro-Bold, sans-serif;
          font-size: 20px;
          text-align: right;
          white-space: nowrap;
        }

        .label {
          color: $purple;
          font-family: ClanPro-Bold, sans-serif;
          font-size: 12px;
          text-align: left;
          margin-right: 8px;
        }
      }

      .accumulated-time {
        margin-top: 40px;

        .time {
          color: $orange;

          &.expired {
            color: $red;

            img {
              margin-right: 8px;
            }
          }
        }
      }
    }

    .log {
      display: flex;
      flex-direction: row;
      position: relative;

      > :first-child {
        margin-right: 12px;
      }

      .delivered {
        color: $green;
      }

      .default {
        color: $purple;
      }

      .reason {
        color: $red;
      }

      &:hover {
        span {
          visibility: visible;
          right: 100%;
          top: 50%;
          margin-top: -26px;
          margin-right: 12px;
          z-index: 999;
        }
      }

      span {
        position: absolute;
        width: max-content;
        color: $white;
        background: $purple;
        text-align: left;
        visibility: hidden;
        border-radius: $border-radius;
        padding: 10px;
        font-size: 11px;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -8px;
          width: 0;
          height: 0;
          border-left: 8px solid #201047;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }

        :first-child {
          font-family: ClanPro-News, sans-serif;
        }

        :last-child {
          font-family: ClanPro-Bold, sans-serif;
        }
      }
    }
  }
}

#no-freezer-logs-container {
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  padding-bottom: 30px;

  .icon {
    width: 204px;
    height: 204px;
    background-image: url('../../../../base/img/freezer-log-empty-state.svg');
    background-size: contain;
    margin-bottom: 5px;
  }

  .header-text {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 20px;
    color: $purple;
    margin-bottom: 15px;
  }

  .sub-text {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: $gray !important;
  }
}
