.weekdays {
  display: grid;
  grid-template-columns: 0px repeat(5, 40px) 0px;
}
.weekdayName {
  color: #eeee;
  position: relative;
  text-align: center;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.showWeekends {
  grid-template-columns: repeat(7, 40px) !important;
}
