.search-result {
  &-filter-container,
  &-pagination {
    display: inline-flex;
    align-items: center;

    > button,
    .page-no {
      min-height: 44px;
      margin: 0 3px;
      font-size: 16px;
    }

    .page-no {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-filter-container {
    label {
      margin: 0 10px;
    }
    select {
      width: 75px;
      margin: 5px;
      height: 44px;

      &:disabled {
        background-color: #ffffff80 !important;
      }
    }
  }
}
