@import './variables';

.title {
  font-family: ClanPro-News, serif;
  font-size: 24px;
  font-weight: bold;
  color: $purple;

  &.bold {
    font-family: ClanPro-Bold, sans-serif;
  }
}

.subtitle {
  font-family: ClanPro-News, serif;
  font-size: 18px;
  color: $purple;

  &.bold {
    font-family: ClanPro-Bold, sans-serif;
  }
}
.search {
  font-family: ClanPro-News, serif;
  font-size: 18px !important;
  color: rgba($purple, 0.5) !important;
}

.text {
  font-family: ClanPro-News, serif;
  font-size: 14px;
  color: $purple !important;

  &.bold {
    @extend .text;
    font-weight: bold;
  }

  &.green {
    color: $green !important;
  }

  &.red {
    color: $red !important;
  }
}

.sub-text {
  @extend .text;
  font-family: ClanPro-Book, serif;
  font-size: 12px;
}

.label {
  font-family: ClanPro-Bold, serif;
  font-size: 12px;
  color: rgba($purple, 0.5);
}

.table-head {
  font-family: ClanPro-Bold, serif;
  font-size: 12px;
  color: rgba($purple, 0.75);
}
.table-cell {
  font-family: ClanPro-News, serif;
  font-size: 12px;
  color: $purple;
  padding: 12px;
}
.menu-link {
  font-family: ClanPro-News, serif;
  font-size: 18px;
  color: $purple;
}

.gray {
  color: $gray !important;
}

.purple {
  color: $purple !important;
}
