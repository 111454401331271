@import '../../base/scss/variables';

#shipment-container {
  > .header {
    display: flex;
    width: 100%;
    height: 100px !important;
    display: flex;
    align-items: center;
  }

  > .title {
    font-size: 24px;
    margin: 20px 0 50px 0 !important;
    font-family: ClanPro-Bold, sans-serif;
    color: #201047;

    > .desc {
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      margin: 6px 0 0 0;
      color: $dark-gray;
    }

    > .sub {
      text-transform: uppercase;
      margin: 12px 0 0 0;
      font-size: 12px;
    }
  }

  > .wrapper {
    padding-bottom: 16px;
  }

  h2.text {
    font-size: 16px !important;
    margin-bottom: 12px;
    font-family: ClanPro-Bold, sans-serif;
  }

  h1 > div {
    display: flex;
    align-items: center;

    > span {
      padding-right: 20px;
      margin-top: -15px;
    }

    > .weight {
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      font-family: ClanPro-News;
    }

    > .tnt-link {
      font-size: 14px;
      font-weight: normal;
      color: $purple;
      font-family: ClanPro-News;

      > div > svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  .tour-progress-label {
    padding-top: 50px;
    text-align: left;
  }

  .product-name-identifier {
    > svg {
      color: $light-blue;
    }
  }

  .pod {
    font-size: 16px;
    font-weight: normal;
    font-family: 'ClanPro-News';
    color: $gray-3;
  }

  .mt-24 {
    margin-top: -24px;
  }

  .shipment-number {
    margin-top: -15px;
  }

  .span-tooltip {
    position: relative;
    display: inline-block;
  }

  .span-tooltip .copy-tooltip-success {
    font-size: 10px;
    background-color: $purple;
    color: $white;
    border-radius: 5px;
    padding: 4px 8px;
    text-align: center;
    font-family: ClanPro-WideNews, sans-serif;
    display: block;
    visibility: hidden;
    margin-left: -18px;
  }

  .visible {
    visibility: visible;
  }
}
