#shipments {
  > .title {
    font-size: 24px;
    margin: 45px 0 32px 0 !important;
  }

  .wrapper {
    padding-bottom: 25px;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 336px) !important;
    position: relative;
  }

  #shipment-loader {
    height: calc(100vh - 233px);
  }
}
