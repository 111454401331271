@import '../../base/scss/variables';

#morning-scan-report {
  padding-left: 24px !important;
  > .title {
    font-size: 20px;
    padding: 40px 0 32px 0;
  }
  .vertical-tab-menu {
    margin-left: 15px !important;
  }

  .region-header {
    font-family: ClanPro-Bold, sans-serif;
    color: $purple;
    font-size: 16px;
    text-align: left;
  }

  .table-container > table {
    .gray {
      color: $gray-5;
    }

    > tr {
      &.scrolling {
        box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
          0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
          0px 8px 12px rgba(34, 12, 74, 0.06),
          0px 16px 24px rgba(34, 12, 74, 0.04);
        clip-path: inset(0px 0px -24px 0px);
      }

      > th:nth-child(1) {
        flex: 0 1 86px;
      }
      > th:nth-child(2) {
        flex: 0 1 108px;
      }
      > th:nth-child(3) {
        flex: 0 1 181px;
      }
      //> th:nth-child(4) {
      //  flex: 0 1 153px;
      //}
      > th:nth-child(4) {
        flex: 0 1 136px;
      }
      > th:nth-child(5) {
        flex: 0 1 154px;
      }
      > th:nth-child(6) {
        flex: 0 1 176px;
      }
      > th:nth-child(8) {
        flex: 0 1 205px;
      }
      > th:nth-child(9) {
        flex: 0 1 36px;
      }
    }
    > tbody {
      max-height: calc(100vh - 224px);

      &.expanded {
        max-height: calc((100vh - 224px) + 40px);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      > tr {
        > td:nth-child(1) {
          flex: 0 1 86px;
        }
        > td:nth-child(2) {
          flex: 0 1 108px;
        }
        > td:nth-child(3) {
          flex: 0 1 181px;
        }
        //> td:nth-child(4) {
        //  flex: 0 1 153px;
        //}
        > td:nth-child(4) {
          flex: 0 1 136px;
        }
        > td:nth-child(5) {
          flex: 0 1 154px;
          white-space: nowrap;
        }
        > td:nth-child(6) {
          flex: 0 1 176px;
        }
        //> td:nth-child(8) {
        //  flex: 0 1 240px;
        //}
        > td:nth-child(8) {
          flex: 0 1 170px;
        }
        > td:nth-child(9) {
          flex: 0 1 36px;
        }
      }
    }
  }

  .table-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: $purple;
    height: 56px;
  }

  .empty-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55vh;

    > svg {
      margin-bottom: 16px;
    }

    .header {
      font-family: ClanPro-Bold, sans-serif;
      font-size: 24px;
      line-height: 36px;
      color: $purple;
      margin-bottom: 16px;
    }

    .sub-details {
      color: $purple;
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      width: 400px;
    }
  }

  .hidden {
    visibility: hidden;
  }
}
