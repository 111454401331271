@import '../../../base/scss/variables';
@import '../../../base/scss/shadow';

#debtors-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  min-width: 230px;

  label {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: left;
    color: $gray;
  }

  .select-field {
    select,
    .MuiOutlinedInput-root {
      height: 45px;
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      color: $purple;
      margin-bottom: 16px;
    }

    .MuiOutlinedInput-root {
      cursor: pointer !important;
      > div {
        text-align: left;
        z-index: 1;
      }
      &.Mui-focused {
        border-color: $blue;
      }

      > svg {
        position: absolute;
        right: 0;
        height: 16px;
        width: 16px !important;
        margin-right: 12px;
        color: #888;
      }
    }

    fieldset {
      border: none !important;
    }

    > div {
      padding: 0 !important;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border-color: $blue !important;
  }

  .form-control {
    border-radius: 8px;
    border-color: $light-gray-4;

    &:hover {
      border-color: $gray;
    }
  }

  .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .MuiFormControl-root {
    z-index: 1;
  }

  .MuiInput-underline.Mui-focused {
    border-color: $blue;
  }

  .invalid-tooltip:not(.warning-notification) {
    display: none !important;
  }
}

.select-menu-popover-container {
  min-width: 230px !important;
  box-shadow: none !important;
  border: 1px solid $blue !important;
  border-top: none !important;
  border-radius: 0 0 8px 8px !important;

  .MuiMenuItem-root.Mui-selected {
    color: $blue;
  }

  .MuiMenuItem-root:hover {
    color: $blue;
  }
}

#btn-save-debtor {
  width: 130px;
  align-self: end;
}
