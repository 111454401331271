@import '../../../../base/scss/variables';

.quick-tour--wrapper {
  background: white;
  margin-top: 24px;
  border-radius: 5px;
  padding: 24px;

  .quick-tour--info-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    .quick-tour--driver-position {
      font-family: ClanPro-Book, sans-serif;
      font-size: 10px;
      color: $gray;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 12px;

      span {
        margin-bottom: 6px;
      }
      .quick-tour--box {
        border: 1px solid $light;
        border-radius: 5px;
        padding: 6px 12px;
        font-family: ClanPro-Bold, serif;
        font-size: 12px;
        color: $purple;
        cursor: pointer;

        a {
          font-size: 10px;
        }
      }
    }

    .quick-tour--current-position {
      margin-right: 12px;
      color: $gray;
    }

    .quick-tour--driver-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: ClanPro-Bold, serif;
        font-size: 18px;
        color: $purple;
      }
      h2 {
        font-family: ClanPro-Book, sans-serif;
        font-size: 12px;
        color: $gray;
      }
    }
  }

  .quick-tour--tour-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .quick-tour--time {
      font-family: ClanPro-Bold, serif;
      font-size: 12px;
      color: $purple;
      width: 50px;
    }

    .quick-tour--stop-bar {
      margin: 0 12px;
      flex: 1;
      background-color: $super-light;
      border-radius: 5px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .quick-tour--stop {
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background-color: $blue;
        position: relative;
        cursor: pointer;

        &.quick-tour--delivered {
          background-color: $green;
        }
        &.quick-tour--delivered-to-neighbour {
          border: 4px solid $green;
          background: $white;
        }
        &.quick-tour--not-delivered {
          background-color: $red;
        }
        &.quick-tour--not-arrived {
          background-color: $yellow;
        }
        &.quick-tour--current {
          background-image: url(./assets/star.svg);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 10px 10px;
          width: 16px;
          height: 16px;
          border-radius: 8px;
        }
        &.quick-tour--not-scanned {
          background: $gray;
        }

        &:hover {
          .quick-tour--overlay {
            display: block;
          }
        }

        .quick-tour--overlay {
          display: none;
          position: absolute;
          background: $white;
          border: 5px;
          padding: 6px;
          width: 120px;
          top: -60px;
          left: -55px;
          border-radius: 5px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12) !important;

          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $white transparent transparent transparent;
          }

          h1 {
            font-family: ClanPro-Bold, serif;
            font-size: 10px;
            color: $purple;
            margin: 0;
          }

          p {
            margin: 0;
            font-family: ClanPro-Book, sans-serif;
            font-size: 8px;
            color: $gray;
          }
        }
      }
    }
  }
  .quick-tour--position-row {
    margin: 12px 62px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0;

    .quick-tour--position {
      margin: 0;
      font-family: ClanPro-Book, sans-serif;
      font-size: 8px;
      color: $gray;
      width: 12px;
      text-align: center;

      &.quick-tour--current {
        width: 16px;
      }
    }
  }
  .quick-tour--legend {
    display: flex;
    padding: 12px;
    background: $super-light;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-family: ClanPro-Book, sans-serif;
    font-size: 10px;
    color: $gray;
    margin: 24px -24px -24px -24px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .quick-tour--stop {
      height: 12px;
      width: 12px;
      border-radius: 6px;
      background-color: $blue;
      position: relative;
      cursor: pointer;
      margin: 0 6px 0 12px;

      &.quick-tour--delivered {
        background-color: $green;
      }

      &.quick-tour--delivered-to-neighbour {
        border: 4px solid $green;
        background: $white;
      }

      &.quick-tour--not-delivered {
        background-color: $red;
      }
      &.quick-tour--not-arrived {
        background-color: $yellow;
      }

      &.quick-tour--current {
        background-image: url(./assets/star.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 8px 8px;
        border-radius: 8px;
      }

      &.quick-tour--not-scanned {
        background: $gray;
      }
    }
  }
}
