@import '../../../scss/variables';

.text-area-container {
  width: 100%;
  position: relative;
}

.text-area {
  outline: none;
  font-size: 12px;
  font-family: ClanPro-News, sans-serif;
  color: $dark-gray;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: 24px;
  padding: 5px;
  background-color: $white;

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: $dark-gray;
    cursor: text;
  }
  &[placeholder]:empty:focus:before {
    display: none;
  }
}
