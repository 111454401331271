@import '../../../../../../base/scss/variables';

.detailsContainer {
  margin-bottom: 16px;
  width: 450px;
}

.calendarContainer {
  display: flex;
  justify-content: center;

  > div:first-of-type {
    @include box-shadow-elevation-1;
  }
}
