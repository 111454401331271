@import '../../../scss/variables';

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray-2;
  padding: 16px;
  align-items: center;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    .title {
      color: $purple !important;
      text-transform: uppercase;
      font-family: ClanPro-Bold, serif !important;
      font-weight: 300;
      line-height: 1.2;
      font-size: 14px !important;
    }

    > span:last-of-type {
      color: $light-gray-3;
      font-family: ClanPro-News, serif;
      line-height: 1.2;
      font-size: 12px;
    }
  }

  .closeIcon {
    color: $gray-2;
  }
}

.listItem {
  height: 48px !important;
  font-family: ClanPro-News, serif;
  color: $purple !important;
  font-size: 12px;
}

.paper {
  background: $white;
  margin-top: 24px;
  min-width: 350px;
}

.paperState {
  background: $white;
  min-width: 966px;
}

.chipInput {
  margin: 0 16px !important;
  font-family: ClanPro-News, serif;
  color: $purple !important;
  font-size: 12px;
}

.hidden {
  display: none !important;
}
