@import '../../scss/variables';

.status_label {
  text-align: center;
  padding: 4px 8px;
}

.loading_shipments {
  font: 11px/16px ClanPro-Bold;
  letter-spacing: 0px;
  color: $dark-gray;
  background: $light;
  border-radius: 9px;
}

.onto_regional {
  font: 11px/16px ClanPro-Bold;
  letter-spacing: 0px;
  color: $blue;
  background: #c1eff9;
  border-radius: 9px;
}

.arrived_regional {
  font: 11px/16px ClanPro-Bold;
  letter-spacing: 0px;
  color: $green;
  background: #bcf0d4;
  border-radius: 9px;
}
