@import '../../../../base/scss/variables';

.merchant-notification-container {
  position: fixed;
  display: flex;
  height: 105px;
  min-width: 420px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px 12px 6px 6px;
  border-bottom: 6px solid $green;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 20px;
  margin-left: -30px;
  align-items: center;
  z-index: 999;

  .icon {
    display: flex;
    flex: 0 0 20%;
    align-items: center;
    justify-content: center;

    svg {
      height: 46px;
      width: 46px;
      color: $green;
    }
  }

  .message-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 80%;
    text-align: left;
    padding-right: 15px;

    .header-text {
      font-family: ClanPro-News, sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: $green;
    }

    .sub-text {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $purple;

      b {
        color: $blue;
      }
    }
  }

  &.error {
    border-bottom-color: $red;
    .icon {
      svg {
        color: $red;
      }
    }
    .message-container {
      .header-text {
        color: $red;
      }
    }
  }
}
