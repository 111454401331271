@import '../../../../../../scss/variables';
.root {
  outline: none !important;
  display: flex;
  overflow: hidden;
  position: relative;
  text-align: center;
  justify-content: center;
}

.hovering {
  :hover,
  :focus {
    border: 4px solid $white;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
.outsideDay {
  cursor: default;
}
.selected {
  background-color: #e1d8ff !important;
}
.start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.selectedDay {
  width: 100%;
  height: 100%;
  background-color: #8664ff;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  color: $white;
  position: relative;
  border: 4px solid #e1d8ff;
}
.hidden {
  opacity: 0;
}
