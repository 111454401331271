@import '../../../scss/variables';

.container {
  display: block;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 2px 4px rgba(34, 12, 74, 0.08);

  > div:last-of-type {
    margin-top: 2px;
  }
}

.label {
  display: flex;
  cursor: pointer;
  padding: 4px 14px 4px 8px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-family: 'ClanPro-News', sans-serif;
  color: $purple;

  &:focus {
    background-color: $gray-4;
  }

  > div:last-of-type {
    width: max-content;
    > svg {
      fill: #220c4a;
      margin-left: 10px;
    }
  }

  &:hover,
  &.active {
    background-color: $gray-4;
  }
}

.dropdownContainer {
  position: absolute;
  display: none;
  height: fit-content;
  background-color: #fff;
  list-style-type: none;
  font-family: 'ClanPro-News', sans-serif;
  font-size: 14px;
  color: $purple;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
    0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
  border-radius: 4px;
  padding: 0;
  z-index: 999;
  max-height: 220px;
  overflow-y: auto;
  flex-direction: column;
  width: auto;
  text-align: left;

  @include scrollbar-min;

  > li {
    padding: 8px 16px;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'ClanPro-News', sans-serif;
    font-size: 14px;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: #8000ff !important;
      color: #fff;
    }

    &.selected {
      background-color: #efefef;
    }
  }

  &.visible {
    display: flex;
  }
}

.clearButton {
  margin-right: 10px;

  &:hover {
    svg {
      > path {
        fill: darken($gray, 20%);
      }
    }
  }
}

.disabled {
  pointer-events: none !important;
  > div {
    background-color: $gray-4;

    > div {
      opacity: 0.2;
    }
  }
}
