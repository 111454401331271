@import '../../../../base/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 4px;

  .header {
    padding: 24px 32px;
    display: flex;
  }

  .body {
    display: flex;
    min-height: 295px;
    border-top: 2px solid $light;
    border-bottom: 2px solid $light;
    padding: 24px;

    > textarea {
      border: 1px solid green;
      outline: none;
      width: 100%;
      resize: none;
    }
  }

  .footer {
    padding: 17px 30px;
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;

    > div {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $dark-gray;
      margin-left: 5px;
    }
  }

  .auto-complete-container {
    width: 100%;
    display: flex;
    margin-left: 25px;
    align-items: center;
    color: $dark-gray;
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
  }

  .auto-complete {
    margin-left: 8px;

    > input {
      width: 450px;
      border: none;
      padding: 0;
      position: relative;
      top: 1px;
    }

    > div > div {
      border-width: 0;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      margin-top: -15px;

      > div:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }

  .type-of-message-select {
    min-width: 200px;
  }

  .option-item {
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    font-family: ClanPro-News, sans-serif;
    color: $purple;
    text-align: left;
    padding: 0 16px;

    &:hover {
      background-color: $light-gray;
    }

    > svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  .blue {
    color: $blue;
  }

  .orange {
    color: $orange;
  }

  .driver-suggestion-label {
    display: flex;
    justify-content: space-between;
  }

  .response-modal {
    background-color: #fff;
    padding: 16px;
    font-size: 12px;
    font-family: ClanPro-News, sans-serif;
    color: $purple;
    border-radius: 4px;
    cursor: pointer;

    &.sending {
      padding: 16px 24px 16px 16px;
    }

    > svg {
      color: $green;
      font-size: 16px;
      margin-right: 8px;
      vertical-align: middle;
    }

    .close-icon {
      cursor: pointer;

      > svg {
        font-size: 16px;
        color: $dark-gray;
        position: relative;
        left: 10px;
        top: 1px;

        &:hover {
          color: darken($dark-gray, 10%);
        }
      }
    }
  }
}
