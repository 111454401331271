@import '../../../../base/scss/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: center;
  color: $purple;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin-top: 50px;
    font-size: 24px;
    line-height: 35px;
    font-family: ClanPro-Bold;
  }

  span {
    font-size: 18px;
    font-family: ClanPro-News;
    margin-top: 5px;
    line-height: 17px;
  }

  .time {
    margin-top: 30px;
    color: $light-blue;
    font-family: ClanPro-Bold;
    font-size: 70px;
    line-height: 103px;
  }

  .time_remaining {
    color: $dark-gray;
    font: ClanPro-News;
    font-size: 11px;
    line-height: 16px;
  }

  .validations {
    margin: 30px auto;
  }
}
