@import '../../../base/scss/variables';

.modal-form {
  background-color: $white;
  border-radius: $border-radius;
  min-width: 450px;

  .title-bar {
    display: flex;
    height: 65px;
    padding: 24px;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 20px;
      margin: 0;
    }

    border-bottom: 2px solid $light;
  }

  .content {
    padding: 24px;

    .status {
      color: $blue !important;
    }
  }

  .actions {
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: flex-end;

    .button {
      min-width: 100px;
      margin: 0 5px;
    }
  }
}
