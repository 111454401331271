@import '../../../../base/scss/variables';

#merchant-shipment {
  .header-wrapper {
    background-color: $blue;
    color: $white;

    .icon {
      background: url('../../../../base/img/merchant-form/header2_bg.svg');
    }
  }

  .input-group-text {
    width: 45px;
    display: flex;
    justify-content: center;
  }

  .warning-notification {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
  }

  .shipment-weight-warning {
    margin-top: -8px !important;
  }

  .file-upload-reset {
    position: absolute;
    margin-top: -70px;
    margin-left: 15px;
  }
}
