@import '../../../../base/scss/variables';

#postpone-shipment {
  width: 740px !important;

  .btn-postpone {
    margin-left: 10px;
    input {
      margin-left: 12px;
      border-radius: $border-radius;
      height: 25px;
      width: 150px;
    }
  }

  textarea {
    min-height: 134px;
  }
}
