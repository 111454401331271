@import '../../scss/variables';

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $light-gray-4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-gray-3;
    box-shadow: none;
    border-radius: 8px;
  }
}

.table {
  padding: 0;
  width: 100%;
}

.tableHeaderRow {
  display: flex;
  margin-bottom: 12px;
  padding: 0 16px;
}

.tableHeader {
  display: flex;
  flex: 1 1;
  font-family: 'ClanPro-WideBold', sans-serif;
  align-items: center;
  color: $purple;
}

.tableBody {
  display: block;
  background-color: $white;
  box-shadow: 0 0 1px rgba(34, 12, 74, 0.12), 0 1px 1px rgba(34, 12, 74, 0.12),
    0 2px 4px rgba(34, 12, 74, 0.08);
  border-radius: 8px !important;

  > tr {
    background-color: $white;
    padding: 0 16px;

    > td {
      color: $purple;
      font-family: ClanPro-WideNews, sans-serif;
      font-size: 12px;
      min-width: 20px;
    }
  }

  > tr:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  > tr:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    > td {
      border-bottom: 0;
    }
  }
}

.tableRow {
  display: flex;
  height: 55px;
}

.tableCell {
  display: flex;
  flex: 1 1;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
