.container {
  background-color: white;
  display: inline-block;
  font-size: 12px;
  font-family: ClanPro-News, serif;
}
.calendar {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.month {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  white-space: nowrap;
  padding: 6px;
  font-size: 12px;
  font-family: ClanPro-News, serif;
}
.spacer {
  height: 40px;
}

.dateGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 0px repeat(5, 40px) 0px;
  grid-template-rows: repeat(6, 40px) 0px 0px 0px;
}
.showWeekends {
  grid-template-columns: repeat(7, 40px) !important;
}
