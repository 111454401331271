@import '../../../../../base/scss/variables';

.item_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 12px 24px;

  &:hover {
    background-color: white;
  }

  .position {
    display: flex;
    justify-content: center;
    font-family: ClanPro-Bold;
    font-size: 18px;
    width: 50px;
    margin-right: 12px;
  }

  .middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: ClanPro-News;
    font-size: 14px;
    color: $dark-gray;
    padding: 12px;

    :nth-child(1) {
      font-family: ClanPro-Bold;
      font-size: 14px;
      color: $purple;
    }

    > .remarks {
      margin-top: 10px;
    }
  }

  .eta {
    font-family: ClanPro-Bold;
    color: $purple;
    font-size: 14px;
  }
}
