.icon_with_label_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;

  div {
    margin-left: 8px;
    margin-top: 3px;
  }
}
