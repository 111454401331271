@import '../../../scss/variables';

.calendar {
  padding: 8px;
}

.caption {
  padding: 4px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      position: relative;
      top: -2px;
      border-color: transparent;

      &:focus,
      &:hover {
        border-color: $gray-2;
      }
    }

    > div:first-of-type {
      margin-left: 8px;
      left: -8px;
    }
  }
}
