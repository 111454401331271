@import '../../../scss/variables';

.container {
  display: block;
  position: relative;
  height: 40px;
  border-radius: 8px;
  border: 2px solid $gray-2;

  &.focused {
    border-color: $purple-2;
  }

  > div:last-of-type {
    margin-top: 2px;
  }
}

.label {
  display: flex;
  cursor: pointer;
  padding: 4px 14px 4px 8px;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  font-family: 'ClanPro-News', sans-serif;
  color: $purple;

  > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > div:last-of-type > svg {
      opacity: 0.8;
      fill: lighten(#220c4a, 10%);
      margin-left: 10px;
    }
  }
}

.dropdownContainer {
  position: absolute;
  display: none;
  height: fit-content;
  background-color: #fff;
  list-style-type: none;
  font-family: 'ClanPro-News', sans-serif;
  font-size: 14px;
  color: $purple;
  box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),
    0px 1px 1px rgba(34, 12, 74, 0.12), 0px 4px 6px rgba(34, 12, 74, 0.08),
    0px 8px 12px rgba(34, 12, 74, 0.06), 0px 16px 24px rgba(34, 12, 74, 0.04);
  border-radius: 4px;
  padding: 0;
  z-index: 999;
  flex-direction: column;
  width: auto;
  text-align: left;

  &.visible {
    display: flex;
  }
}

.clearButton {
  margin-right: 10px;

  &:hover {
    svg {
      > path {
        fill: darken($gray, 20%);
      }
    }
  }
}

.disabled {
  pointer-events: none !important;
  > div {
    background-color: $gray-4;

    > div {
      opacity: 0.2;
    }
  }
}

.timePickerContainer {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  border-top: 1px solid $gray-4;

  > div:last-of-type {
    display: flex;
    align-items: center;

    > button {
      height: 32px;
      padding: 8px;
    }
  }

  input {
    font-family: 'ClanPro-News', sans-serif;
    font-size: 14px;
    color: $purple;
    width: 34px;
    border: 0;
    outline: 0;
    margin: 0;
    position: relative;
    top: 1px;
    text-align: right;
    padding: 0 2px;

    &:last-of-type {
      margin-right: 16px;
      text-align: left;
    }

    &::placeholder {
      color: $purple !important;
      opacity: 1;
    }
  }
}
