@import '../../../../base/scss/variables';

.notification-container {
  width: 656px;
  height: 100%;
  position: fixed;
  top: 0;
  //left: -656px;
  animation-delay: 1s;
  background: $white;
  padding: 60px 30px 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.5s;
  transform: translate(-656px);
  z-index: 103;

  &.collapsed {
    transform: translate(70px);
  }

  &.notCollapsed {
    transform: translate(280px);
  }

  .header-wrapper {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > h1 {
      margin: 0 10px;
    }

    .btn.close {
      position: absolute;
      left: 18px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .mark-all,
    .clear-all {
      font-size: 12px;
      font-weight: bold;
      font-family: ClanPro-Bold, sans-serif;
    }
  }

  .content-wrapper,
  .content {
    width: 100%;
    height: 100%;
  }

  .btn.active {
    font-family: ClanPro-Bold, sans-serif;
    font-weight: bold;
    color: $purple !important;
  }

  .content-wrapper {
    &.not-sorted {
      .tab.btn:nth-child(1) {
        @extend .btn.active;
      }
      .content {
        &.not-accepted,
        &.late-shipments {
          display: none;
        }
      }
    }
    &.not-accepted {
      .tab.btn:nth-child(2) {
        @extend .btn.active;
      }
      .content {
        &.not-sorted,
        &.late-shipments {
          display: none;
        }
      }
    }
    &.late-shipments {
      .tab.btn:nth-child(3) {
        @extend .btn.active;
      }
      .content {
        &.not-sorted,
        &.not-accepted {
          display: none;
        }
      }
    }
  }

  .content {
    overflow-y: auto;
    height: calc(100vh - 228px);
  }

  .tabs-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .text {
      padding: 6px !important;
      font-size: 12px !important;
      color: $gray !important;
    }

    .badge {
      margin-left: 5px;
    }
  }

  .notification {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    > div:first-of-type {
      display: flex;
    }

    .user-icon {
      width: 30px;
      height: 30px;
    }
    .details-container {
      text-align: left;
      padding-left: 15px;
      font-size: 12px !important;
      p {
        margin: 0 0 5px 0;
      }
      .tour-no {
        color: $gray !important;
      }
    }
    .custom-checkbox {
      align-self: flex-start;
      width: 60px;
    }

    .btn.close {
      display: flex;
      height: 22px;
      max-width: 22px !important;
      align-self: flex-start;
      margin-top: 2px;
    }

    .btn.select-shipment {
      color: $blue !important;
      padding: 0 2px;
      height: 26px;
      line-height: 26px;
      margin-top: -3px;
      cursor: pointer;
    }
  }

  .no-notification {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 50px;
    align-items: center;

    .title {
      margin-top: 40px;
      font-family: ClanPro-Bold, sans-serif;
    }

    .text {
      color: $gray !important;
      max-width: 280px;
    }
  }
}
