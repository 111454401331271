@import '../../../../base/scss/variables';

.passcode-container {
  display: flex;
  justify-content: center;

  .digit-box {
    border: 1px $gray solid;
    border-radius: 8px;
    height: 75px;
    width: 65px;
    margin-right: 20px;
    position: relative;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $purple;
    font-family: ClanPro-Bold, sans-serif;
    font-size: 32px;
  }
}

.button-container {
  display: flex;
  margin-top: 20px;

  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}
