@import '../../base/scss/variables';
@import '../../base/scss/shadow';

#uploadcsv-container {
  > .title {
    font-size: 24px;
    margin: 60px 0 20px 0 !important;
  }

  > .subtitle {
    margin-bottom: 42px;
  }

  .search-icon,
  .down-icon {
    display: flex;
    position: absolute;
    font-size: 24px !important;
    height: 100%;
    align-items: center;
  }

  .search-icon {
    left: 30px;
  }

  .down-icon {
    top: 0;
    right: 0;
    padding-right: 35px;
  }

  .actions {
    display: flex;
    position: absolute;
    height: 100%;
    top: 0;
    right: 30px;
    align-items: center;

    button {
      margin-left: 10px;
      font-size: 12px;
      border-radius: 20px;
    }

    .btn-postpone {
      input {
        margin-left: 12px;
        border-radius: $border-radius;
        height: 25px;
        width: 150px;
      }
    }

    .btn-print {
      &:hover {
        background-color: $yellow;
        color: #70570b;
      }
    }
  }

  input.search {
    height: 60px;
    line-height: 60px;
    padding-left: 50px;
    padding-right: 50px;

    &.searching {
      padding-right: 100px;
    }
  }

  #csv-drop-box {
    margin-top: 24px;
    padding: 36px;
    height: 220px;
    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid $blue;
    justify-content: center;
    align-items: center;

    &.active {
      border: 2px dashed $blue;
    }

    &.for-uploading {
      border-color: $green;
    }

    .icon svg {
      font-size: 24px !important;
      color: $blue;
      margin-bottom: 20px;
    }

    .icon.file svg {
      font-size: 48px !important;
      color: $green !important;
    }

    .text {
      color: $gray !important;
    }

    button.action {
      width: auto;
    }

    button.danger {
      margin-top: 10px;
    }
  }

  .shipments-table {
    margin-top: 36px;

    .no-results {
      color: $gray !important;
      text-align: left;
      font-size: 12px;
    }
  }

  .autocomplete {
    position: absolute;
    width: calc(100% - 48px);
    margin-top: -4px;
  }

  #sender-autocomplete {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .list-group-item {
      padding: 10px;
    }

    .list-group-item {
      border-top: 0;
      border-bottom: 0;
    }

    .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }

    .letter {
      width: 50px;
    }
  }
}
