.time-field {
  width: 110px !important;
  font-size: large;
  text-align: center;
  border: 0px;
}

.time-field:focus {
  box-shadow: 0 0 0 0rem #ced4da !important;
}
