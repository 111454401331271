@import '../../../../../base/scss/variables';

.main_container {
  background-color: $blue;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  border-radius: $border-radius;
  background-image: url(../../../../../base/img/departure_arrival_bg.png);
  background-size: cover;
  margin-top: 36px;
  margin-bottom: 20px;

  .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px;

    :nth-child(1) {
      font-family: ClanPro-Bold;
      font-size: 24px;
    }

    :nth-child(2) {
      font-family: ClanPro-News;
      font-size: 12px;
    }
  }

  .middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
      flex-grow: 1;
      background-color: white;
      height: 2px;
      margin: 0px 10px;
    }
  }
}
