@import '../../../../../../base/scss/variables';

.title {
  color: $violet;
  text-transform: uppercase;
  font-weight: bold;
}

.header-row {
  th {
    border-bottom: unset;
  }
}

.body {
  box-shadow: 0 0 0 1px rgb(224, 224, 224);
  border-radius: 8px;

  .row {
    td {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }
    &:first-child {
      td {
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
    &:last-child {
      td {
        border-bottom: unset;
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
  .has-error {
    background-color: $orange !important;
  }
}

.button {
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px 0px;
}

.edit {
  background-color: $white !important;
  color: $green !important;
  &:hover {
    color: darken($green, 0.8) !important;
    background-color: darken($white, 0.8) !important;
  }
}

.close {
  box-shadow: unset;
  background-color: $white !important;
  &:hover {
    background-color: darken($white, 0.8) !important;
  }
}
