@import '../../../../base/scss/variables';
.address {
  display: flex;
  width: 100%;
  text-align: left;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid $light-gray-4;
    border-radius: 4px;
    font-family: 'ClanPro-WideBold', sans-serif;
    font-weight: bold;
    font-size: 10px;
  }

  > div:last-of-type {
    > div:last-of-type {
      color: $light-gray-3;
      font-size: 10px;
    }
  }
}

.timeInput {
  border: 1px solid #cccccc;
}

.menu {
  display: flex;
  justify-content: flex-end;
  width: 32px;
  cursor: pointer;
  padding: 8px;
  margin-left: 20px;

  &.open {
    background-color: #e0e0e0;
  }
}
.popper {
  background: $white;
  color: $purple;
  padding: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  &:hover {
    background-color: darken($white, 15%);
  }
}

.row {
  position: relative;
  overflow: hidden;
}

.highlighted {
  background-color: #c4f4fe !important;
}

.highlighted::before {
  content: '';
  height: 100%;
  width: 4px;
  background-color: #2ce27e;
  position: absolute;
  left: 0px;
}

.modified {
  background-color: #f5f5f5 !important;
}

.menupopup {
  display: flex;
  flex-direction: column;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $gray-light;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  button {
    margin: 4px;
  }
}
