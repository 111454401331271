@import '../../scss/variables';

.layout_main_container {
  display: flex;

  .drawer {
    .drawer_paper {
      width: $right-drawer-width;
      background-color: $super-light;
    }
  }

  .content {
    flex: 1;
    flex-grow: 2;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .content_shift {
    transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    margin-right: $right-drawer-width - 24px;
    padding-right: 24px;
  }

  .scrollable {
    overflow: auto;
    height: 100vh;
  }
}
