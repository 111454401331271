@import '../../../../base/scss/variables';

#merchant-webshop-info {
  .header-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $green;
    color: $white;

    .icon {
      background: url('../../../../base/img/merchant-form/header1_bg.svg');
    }
  }

  #webshop-zipcode {
    text-transform: uppercase;
  }
}
