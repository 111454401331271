@import '../../../../base/scss/variables';

#address-history {
  width: 550px !important;

  p {
    text-align: left;
    margin-bottom: 5px;
  }

  .address-details {
    padding: 12px;
    margin-top: 7px;
    border: 2px solid $light;
    border-radius: $border-radius;

    p {
      font-weight: 300 !important;
    }
  }

  #new-address {
    border-top: 2px solid $light;
    padding-top: 20px;
    margin-top: 25px;

    .timestamp {
      color: $gray !important;
      font-size: 11px;
    }
  }
}
