.shipments-table {
  // min-height: calc(100vh - 501px) !important;

  border-spacing: 0 !important;

  .table-heading {
    margin: 3px 5px 24px 5px;
    th {
      font-family: 'ClanPro-Bold', sans-serif;
      font-size: 10px;
      color: #220c4a !important;
    }
    th:first-of-type {
      padding-left: 16px;
    }
  }

  .status {
    width: 280px;
  }

  > tbody > tr {
    height: 56px !important;
    background-color: #fff;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    overflow: hidden !important;
    border-radius: 0 !important;

    filter: drop-shadow(0px 4px 1px rgba(34, 12, 74, 0.12))
      drop-shadow(0px 4px 1px rgba(34, 12, 74, 0.12))
      drop-shadow(0px 5px 4px rgba(34, 12, 74, 0.08));

    &:nth-of-type(1) {
      filter: drop-shadow(0px 0px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 1px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 2px 4px rgba(34, 12, 74, 0.08)) !important;
    }

    &:nth-of-type(2) {
      filter: drop-shadow(0px 4px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 4px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 5px 4px rgba(34, 12, 74, 0.08)) !important;
    }

    &:last-of-type {
      filter: drop-shadow(0px 2px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 2px 1px rgba(34, 12, 74, 0.12))
        drop-shadow(0px 2px 2px rgba(34, 12, 74, 0.08)) !important;
      td:first-of-type:after {
        display: none !important;
      }
    }

    td {
      border-radius: 0 !important;
      font-family: ClanPro-News, sans-serif;
      color: #220c4a !important;

      &:first-of-type {
        padding-left: 16px;
      }

      &:last-of-type {
        padding-right: 16px;
      }

      &:first-of-type:after {
        content: '';
        display: block;
        position: absolute;
        width: auto;
        background-color: red;
        border: 1px solid #e0e0e0;
        bottom: 0;
        left: 16px;
        right: 16px;
      }

      .address-info {
        div:last-of-type {
          font-size: 10px;
          color: #666;
        }
      }
    }

    &:first-child {
      td:first-of-type {
        border-top-left-radius: 8px !important;
      }
      td:last-of-type {
        border-top-right-radius: 8px !important;
      }
    }

    &:last-child {
      td:first-of-type {
        border-bottom-left-radius: 8px !important;
      }
      td:last-of-type {
        border-bottom-right-radius: 8px !important;
      }
    }
  }
}

#no-shipments-found {
  > img.icon {
    width: 164px;
    height: 142px;
  }

  > label {
    margin-top: 20px;
  }

  height: calc(100vh - 233px) !important;
}
