@import '../../scss/variables';

.label {
  font-size: 12px;
  font-family: ClanPro-News, sans-serif;
  color: #888888;
}

.avatar {
  width: 26px !important;
  height: 26ox !important;
}

.fallback {
  width: 100% !important;
  height: 100% !important;
  color: $dark-gray;
}
