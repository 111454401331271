$grey: #ccc;
$white: #fff;
$blue: #13d2fc;
$purple: #8000ff;

.checkboxContainer {
  display: block;
  position: relative;
  //padding-left: 45px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-family: 'ClanPro-News', sans-serif;
  font-weight: bold;
  font-size: 12px;
  user-select: none;
  line-height: 27px;
  color: $purple;

  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ span {
    border-color: $purple;
  }

  input:checked ~ span {
    border-color: $purple;
    background-color: $purple;
  }

  input:checked ~ span:after {
    display: block;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $white;
  border: 2px solid $grey;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 0;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.checkBar {
  &:after {
    left: 2px;
    top: 9px;
    width: 16px;
    height: 0;
    border-width: 1px;
    transform: none !important;
  }
}
