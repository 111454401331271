@import '../../../../base/scss/variables';

.file-upload-container {
  &:focus {
    outline: 0 !important;

    .merchant-photo-content {
      border-color: darken($blue, 15%);
    }
  }
  button > svg {
    margin-right: 8px;
  }
}

.merchant-photo-content {
  border: dashed 2px lighten($blue, 10%);
  border-radius: 8px;
  height: 150px;
  padding: 24px;

  &:focus {
    outline: 0 !important;
  }

  &.dragging {
    border-color: darken($blue, 15%);
  }

  &.uploaded {
    border: 0;
    padding: 5px;
  }

  .upload-action-text {
    margin-top: 16px;
    font-size: 10px;
    color: $gray;
    font-family: ClanPro-News, sans-serif;
    > div {
      margin-top: 13px;
      font-size: 12px;
      color: $blue;
      font-family: ClanPro-News, sans-serif;
    }
  }

  .photo-content-rejected {
    .header-text,
    .sub-text {
    }

    .header-text {
      font-family: ClanPro-Bold, sans-serif;
      font-size: 14px;
      color: $red;
      margin-bottom: 15px;
    }

    .sub-text {
      font-family: ClanPro-News, sans-serif;
      font-size: 12px;
      color: $purple;
      margin-bottom: 10px;
    }
  }

  .photo-content-uploaded {
    display: flex;
    justify-content: center;
    align-items: center;

    .img-thumbnail-container {
      flex: 150px 0 0;
    }

    .file-description-container {
      flex: 1 0 auto;
      text-align: left;
      margin-left: 20px;
      height: 100px;

      .file-description {
        margin-bottom: 18px;
      }

      .name,
      .size {
        font-family: ClanPro-News, sans-serif;
        font-size: 12px;
        color: $purple;
        text-overflow: ellipsis;
      }

      .name {
        overflow: hidden;
        max-width: 485px;
      }

      .size {
        color: $gray;
      }
    }

    .img-thumbnail {
      width: 150px;
      height: 100px;
      border: 0;
      padding: 0;
    }
  }
}
