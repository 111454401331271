.container {
  tr {
    overflow: hidden;

    td {
      padding: 0px !important;
      overflow: hidden;

      .bar {
        background-color: white;
      }
    }
  }
}
