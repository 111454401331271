.modalBox {
  > div:first-of-type {
    top: 20%;
  }
}

.detailsContainer {
  margin-bottom: 16px;

  > div:first-of-type {
    margin-bottom: 16px;
  }
}

.calendarLabel {
  font-size: 12px;
  font-family: ClanPro-Bold, sans-serif;
  margin-bottom: 8px;
}

.calendarContainer {
  > div:nth-child(2) {
    > div:first-of-type {
      width: 100%;

      div[class='DayPicker-Month'] {
        width: 100%;
      }
    }
  }
}
