@import '../../../../base/scss/variables';

.shipment-state-label {
  font-size: 12px;
  font-family: ClanPro-WideNews, sans-serif;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #e0e0e0;

  > span:first-of-type {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  .notDelivered {
    background-color: $red !important;
  }
  .delivered {
    background-color: $green !important;
  }
  .toNeigbour {
    border: 1px solid $green !important;
  }
  .acceptedByDriver {
    background-color: #ffd65a !important;
  }
  .inTransit {
    background-color: $blue !important;
  }
}
