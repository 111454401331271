@import '../../../scss/variables';

.container {
  display: block;
  position: relative;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $gray-2;

  &.min {
    height: 26px;
  }
}

.label {
  display: flex;
  cursor: pointer;
  padding: 16px 8px;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  font-family: 'ClanPro-News', sans-serif;
  color: $purple;

  &.min {
    padding: 4px 8px;
    font-size: 14px;
  }

  &:focus {
    background-color: $gray-4;
  }

  > div:last-of-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    > svg {
      fill: #220c4a;
      margin-left: 10px;
    }
  }
  &:hover,
  &.active {
    background-color: $gray-4;
  }
}

.dropdownContainer {
  position: relative;
  top: -2px;
  left: -1px;
  display: none;
  height: fit-content;
  border: 1px solid $gray-2;
  background-color: #fff;
  list-style-type: none;
  font-family: 'ClanPro-News', sans-serif;
  font-size: 14px;
  color: $purple;
  border-radius: 0 0 8px 8px;
  padding: 0;
  z-index: 999;
  max-height: 220px;
  overflow-y: auto;
  flex-direction: column;
  width: calc(100% + 2px);
  text-align: left;

  @include scrollbar-min;

  > li {
    padding: 8px 16px;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'ClanPro-News', sans-serif;
    font-size: 14px;

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: #8000ff !important;
      color: #fff;
    }

    &.selected {
      background-color: #efefef;
    }
  }

  &.visible {
    display: flex;
  }
}

.clearButton {
  margin-right: 10px;

  &:hover {
    svg {
      > path {
        fill: darken($gray, 20%);
      }
    }
  }
}

.disabled {
  pointer-events: none !important;
  > div {
    background-color: $gray-4;

    > div {
      opacity: 0.2;
    }
  }
}

.placeholder {
  color: $gray-3;
}
