.notification-popup {
  opacity: 1;
  max-height: 80px;

  &.fadeout {
    transition: all 2s ease-in-out;
    max-height: 0;
    opacity: 0;
  }
}
