.no_results {
  margin-top: 10%;
}

.main_title {
  font-size: 24px;
  margin: 50px 0 !important;
}

.tour_table {
  tr {
    overflow: hidden;

    td {
      padding: 0 !important;
      overflow: hidden;
    }
  }
}

.noTours {
  font-size: 16px;
  color: #201047;
  font-family: ClanPro-News, sans-serif;
  margin-top: 40px;
}
