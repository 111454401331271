.root {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  button {
    margin-left: 8px;
  }
}

.time_input {
  border: 1px solid #cccccc;
}
