@import '../../../../base/scss/variables';

#shipment-actions-panel {
  > .wrapper {
    border-radius: $border-radius;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;

    button {
      color: $white !important;
      font-size: 12px;
      padding: 12px 5px;
      min-width: 30%;
    }
    .overrule {
      color: $purple !important;
      font-size: 12px;
      background: $gray-4;
      padding: 12px 5px;
      min-width: 30%;
      margin: 10px 0;
    }
  }

  > .modal-section {
    text-align: left;
  }
}
