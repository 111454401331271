@import 'variables';

.table {
  border-collapse: separate;
  border-spacing: 0 6px;

  tr,
  td,
  th {
    border: 0 !important;
    text-align: left;
  }

  thead {
    > tr {
      th {
        @extend .table-head;
        margin-bottom: 20px;
      }
    }
  }

  tbody {
    tr {
      @extend .box-shadow;
      height: 50px;
      border-radius: $border-radius;
      background-color: $white;
      cursor: pointer;
      margin-bottom: 6px;
      width: 100%;
      overflow: hidden;

      &:hover {
        background-color: darken($white, 2%);
      }

      td {
        @extend .table-cell;
        vertical-align: middle !important;
        padding: 0 12px;
        &:first-of-type {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
        &:last-of-type {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  .row:not(.table-heading) {
    @extend .box-shadow;
    min-height: 50px;
    border-radius: $border-radius;
    margin: 3px 0 6px 0;

    .col {
      @extend .table-cell;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $super-light;
      box-shadow: none;
    }
  }

  .flex-grow-1 {
    flex-grow: 2 !important;
  }
}
