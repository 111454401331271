@import '../../../../base/scss/variables';

#expected-panel {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;

  > h2.text {
    font-size: 16px !important;
    margin-bottom: 12px;
    font-family: ClanPro-Bold, sans-serif;
  }

  > .wrapper {
    min-height: 224px;
    border-radius: $border-radius;
    background-color: $white;
    padding: 24px;
    flex: 1 2;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    font-family: ClanPro-Bold, sans-serif;

    .status {
      margin-bottom: 16px;
    }

    .date {
      font-size: 18px;
      font-weight: bold;
      color: #201047;
      letter-spacing: 0;
      opacity: 1;
    }

    .time {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      color: $blue;
      opacity: 1;
      position: relative;
      top: 10px;

      &.delivered,
      &.not-delivered,
      &.investigation {
        font-family: ClanPro-News, sans-serif;
        font-weight: normal !important;
        color: $gray-3;
        font-size: 14px;
      }

      &.not-delivered {
        color: $red-3 !important;
        font-size: 14px;
      }

      &.investigation {
        color: $blue !important;
        font-size: 14px;
      }

      > p {
        color: $purple !important;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .alert-info {
      border-radius: 8px;
      height: 44px;
      display: flex;
      background-color: $light-blue !important;
      position: relative;
      top: 30px;
      margin-left: -15px;
      margin-right: -15px;

      .icon {
        flex: 0 0 44px;
        background-color: $blue;
        color: $white;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
      }

      .text {
        font-family: ClanPro-News, sans-serif;
        color: $purple;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
      }
    }
  }
}
