@import '../../scss/variables';

.loader {
  background-color: #201047 !important;
  text-align: center !important;
  border-radius: 5px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12) !important;
  padding: 24px;

  .content {
    width: 320px !important;

    .logo {
      margin-top: 20px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 150px;
      height: 70px;
    }

    .text {
      padding: 24px;
      color: #fff !important;
      font-family: ClanPro-Book, sans-serif;
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .spinner {
    height: 48px;
    width: 48px;
  }
}

.lazy-loading {
  .spinner-border {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #00d763; /* Blue */
    border-radius: 50%;
    width: 130px;
    height: 130px;
    animation: spin 0.6s linear infinite;
    margin: 0 auto;
  }

  .caption {
    text-align: center;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    font-family: ClanPro-News, serif;
    color: rgba($purple, 0.7);
    margin-top: 15px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
