@import '../../../../base/scss/variables';

.emptyResultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55vh;

  > svg {
    margin-bottom: 16px;
  }

  .header {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 24px;
    line-height: 36px;
    color: $purple;
    margin-bottom: 16px;
  }

  .subDetails {
    color: $purple;
    font-family: ClanPro-News, sans-serif;
    font-size: 14px;
    width: 400px;
  }
}
