.search-bar-container {
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  outline: 0;
  display: flex;
  position: fixed;
  top: 0;
  padding: 15px;
  background: #0000004a;
  margin-left: -27px;

  > div {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    z-index: 1;
    width: calc(100% - 345px);
    height: 180px;
    padding: 15px;
  }
}
