.publishing-enter {
  opacity: 0;
  transform: scale(0.9);
}

.publishing-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.publishing-exit {
  opacity: 1;
}

.publishing-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}
