.icon {
  &.clickable {
    cursor: pointer;
  }
  &.disabled {
    pointer-events: none !important;
    opacity: 0.5;
  }
  &.spin {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
