@import '../../../../../base/scss/variables';

.collection_list {
  border-spacing: 0px 25px;

  tbody {
    tr {
      cursor: default;
      td {
        padding: 0px !important;
      }
    }

    tr:hover {
      background-color: white;
    }
  }

  .item_container {
    padding: 24px;
  }

  .position_status {
    display: flex;
    align-items: center;
    align-content: stretch;

    :nth-child(1) {
      font-size: 20px;
      font-family: ClanPro-Bold, sans-serif;
      margin-right: 18px;
    }

    .reason {
      color: $red;
      font-size: 12;
      font-family: ClanPro-News, sans-serif;
      padding: 0px 10px;
    }

    .filler {
      flex-grow: 1;
      min-width: 16px;
    }

    .remarks {
      margin-right: 0px !important;
    }
  }

  .stop_info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-family: ClanPro-News, sans-serif;

    .shop {
      width: 150px;
      max-width: 150px;
    }

    div {
      display: flex;
      flex-direction: column;

      .label {
        font-size: 12px;
        color: $dark-gray;
        font-family: ClanPro-News, sans-serif;
      }

      .data {
        font-size: 14px;
        font-family: ClanPro-Bold, sans-serif;
        color: $dark-gray;
        margin-top: 12px;
      }

      .address {
        color: $dark-gray;
        font-size: 12px;
        font-family: ClanPro-News, sans-serif;
        white-space: nowrap;
      }
    }
  }
}
