@import '../../../../../../base/scss/variables';

.empty-call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: $border-radius;
  padding: 40px;

  img {
    width: 187px;
    height: 119px;
    margin: 0 1px 40px 2px;
  }

  .header {
    font-family: ClanPro-Bold, sans-serif;
    font-size: 12px;
    color: $purple;
  }

  .sub-details {
    font-family: ClanPro-News, sans-serif;
    font-size: 12px;
    color: $light-gray-4;
    margin-top: 8px;
    width: 200px;
  }
}
